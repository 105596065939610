import GlobalHeader from "components/organisms/GlobalHeader";
import HomeMain from "components/templates/home/HomeMain";
import { useEffect, useRef, useState } from "react";
import AlertPop from "components/templates/popup/AlertPop";
import Footer from "components/organisms/Footer";

export default function Home() {
  // 경고 메시지 State
  const [popState, setPopState] = useState(false);

  const [isScrolledPastFirst, setIsScrolledPastFirst] = useState(false);
  const firstComp = useRef(null);
  const secondComp = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.target === firstComp.current) {
          setIsScrolledPastFirst(!entry.isIntersecting);
        }
      },
      {
        threshold: 0,
      }
    );

    if (firstComp.current) {
      observer.observe(firstComp.current);
    }

    return () => {
      if (firstComp.current) {
        observer.unobserve(firstComp.current);
      }
    };
  }, []);

  return (
    <>
      <div className="container home">
        <GlobalHeader
          headerClass={"home"}
          isScrolledPastFirst={isScrolledPastFirst}
        />
        <HomeMain firstComp={firstComp} secondComp={secondComp} />

        {/* 경고 메시지 */}
        {popState && <AlertPop popState={popState} setPopState={setPopState} />}
      </div>
      <Footer />
    </>
  );
}
