import cookie from "react-cookies";

const isLocal =
  `${process.env.REACT_APP_HOST}`.indexOf("localhost") > 0 ? true : false;

export const auth = {
  getMyInfo: () => {
    return localStorage.getItem("myInfo")
      ? localStorage.getItem("myInfo")
      : sessionStorage.getItem("myInfo")
      ? sessionStorage.getItem("myInfo")
      : null;
  },
  setMyInfo: (myInfo) => {
    sessionStorage.setItem("myInfo", myInfo);
    local.getAutoLoginToken() && localStorage.setItem("myInfo", myInfo);
  },
};

export const local = {
  getKeepId: () => localStorage.getItem("keepId"),
  setKeepId: (memberEmail) => {
    localStorage.setItem("keepId", memberEmail);
  },

  //headers: { Authorization: `Bearer ${access_token}` },
  getAutoLoginToken: () => localStorage.getItem("autoLoginToken"),
  setAutoLoginToken: (token) => {
    localStorage.setItem("autoLoginToken", token);
  },

  getLoginType: () => localStorage.getItem("loginType"),
  setLoginType: (type) => {
    localStorage.setItem("loginType", type);
  },

  removeKeepId: () => {
    localStorage.removeItem("keepId");
  },

  removeAutoLoginToken: () => {
    localStorage.removeItem("jeboRefreshToken");
  },

  removeMyInfo: () => {
    sessionStorage.removeItem("myInfo");
    localStorage.removeItem("myInfo");
  },
};

export const session = {
  //getAuth
  getAuthorization: () =>
    sessionStorage.getItem("authorization")
      ? sessionStorage.getItem("authorization")
      : cookie.load("authorization"),
  getRefreshToken: () => sessionStorage.getItem("refresh_token"),
  getEmail: () => sessionStorage.getItem("email"),
  getMemberUid: () => sessionStorage.getItem("memberUid"),
  getSocialYn: () => sessionStorage.getItem("socialYn"),
  getLoginType: () => sessionStorage.getItem("loginType"),
  setLoginType: (type) => {
    sessionStorage.setItem("loginType", type);
  },

  setAuth: (
    authorization,
    refreshToken,
    email,
    memberUid,
    socialYn,
    loginType
  ) => {
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + 120);
    if (authorization) {
      sessionStorage.setItem("authorization", authorization);
      // 23.10.19.codelua 보안상 필요하다면 추가하고 값들도 인코딩 처리하자 일단은 그냥  secure : true, // httpOnly : true
      cookie.save("authorization", authorization, {
        path: "/",
        expires,
        domain: isLocal ? "" : "meswiser.ai",
      });
    }
    if (refreshToken) {
      sessionStorage.setItem("refresh_token", refreshToken);
      cookie.save("refresh_token", refreshToken, {
        path: "/",
        expires,
        domain: isLocal ? "" : "meswiser.ai",
      });
    }
    if (email) {
      sessionStorage.setItem("email", email);
      cookie.save("email", email, {
        path: "/",
        expires,
        domain: isLocal ? "" : "meswiser.ai",
      });
    }
    if (memberUid) {
      sessionStorage.setItem("memberUid", memberUid);
      cookie.save("memberUid", memberUid, {
        path: "/",
        expires,
        domain: isLocal ? "" : "meswiser.ai",
      });
    }
    if (socialYn) {
      socialYn && sessionStorage.setItem("socialYn", socialYn);
      cookie.save("socialYn", socialYn, {
        path: "/",
        expires,
        domain: isLocal ? "" : "meswiser.ai",
      });
    }
    if (loginType) {
      sessionStorage.setItem("loginType", loginType);
      cookie.save("loginType", loginType, {
        path: "/",
        expires,
        domain: isLocal ? "" : "meswiser.ai",
      });
    }
  },
  removeAuth: () => {
    sessionStorage.removeItem("authorization");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("memberUid");
    localStorage.removeItem("autoLoginToken");
    // auth와 상관없으나 로그아웃 시 해제필요
    sessionStorage.removeItem("botData");
    sessionStorage.removeItem("socialYn");
    sessionStorage.removeItem("loginType");
    //쿠키 시리즈는 시간이 지나면 없애지겠지만 로그아웃 시 명시적 삭제.
    cookie.remove("authorization");
    cookie.remove("refresh_token");
    cookie.remove("email");
    cookie.remove("memberUid");
    cookie.remove("socialYn");
    cookie.remove("loginType");
    sessionStorage.removeItem("DISPLAY_MENU");
  },
};
