import "assets/styles/reset.css";
import "assets/styles/style.css";
import botApi from "api/botApi";
import AppContextProvider from "utils/AppContextProvider";
import { BrowserRouter } from "react-router-dom";
import Route from "components/pages/route";
import { useMediaQuery } from "react-responsive";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";

function App() {
  // ChatBot Provider 설정 처리
  const [chatBotData, setChatBotData] = useState(null);
  const [chatRoomId, setChatRoomId] = useState(null);

  const [myIP, setMyIP] = useState(null);

  // chatBotData가 바뀔때마다 session에 정하도록 한다.
  useEffect(() => {
    if (!chatBotData) {
      // 일단 암거도 안함.
    } else {
      // 어디선 setChatBotData가 호출 되면 세션을 처리하기 위함.
      // 다만 진행중인 챗봇은 해당 화면에서만 사용되도록 공유하지 않음.
      if (chatBotData.status !== "00") {
        const stateToPass = JSON.stringify(chatBotData);
        sessionStorage.setItem("botData", stateToPass);
      } else if (chatBotData.status === "00") {
        sessionStorage.removeItem("botData");
      }
    }
  }, [chatBotData]);

  // 23.07.06.codelua. 로그인여부와 상관없이 누구든 IP정보를 획득하도록 한다.
  useEffect(() => {
    axios.get("https://geolocation-db.com/json/").then((res) => {
      setMyIP(res.data.IPv4);
    });
  }, []);

  const isMobile = useMediaQuery({
    query: "screen and (min-width: 0px) and (max-width: 480px)",
  });

  return (
    <AppContextProvider
      isMobile={{ isMobile }}
      chatBotData={{
        chatBotData,
        setChatBotData,
        chatRoomId,
        setChatRoomId,
        myIP,
        setMyIP,
      }}
    >
      <BrowserRouter>
        <Route />
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
