export function ErrorCatch(error, handleSignOutApp) {
  /* axios error catch 용 
        - 자주사용되는 공통 Error 코드
        - setState()가 사용되는 catch에는 적용 안함
        - react catch절에서 hook을 사용 할 수없기 때문에
          js 문법으로 작성
     */
  let errorMsg = "";
  let errorCode = "";

  if (error?.response) {
    const response = error.response;
    if (response?.status) errorCode = response.status;

    if (response.status === 401) {
      errorMsg = response.data?.errorDescription
        ? response.data.errorDescription
        : "일치하는 회원정보가 없습니다.";
    } else if (response.status === 403) {
      errorMsg = "세션이 만료되었습니다. 다시 로그인 해주세요.";
      handleSignOutApp();
    } else if (response.status === 409) {
      errorMsg = response.data?.errorDescription
        ? response.data.errorDescription
        : "이미 생성된 데이터 입니다.";
    } else if (response.status === 500) {
      errorMsg = "진행 중 오류가 발생했습니다.";
    } else if (response.status === 503) {
      errorMsg = response.errorMessage
        ? response.errorMessage
        : "네트워크 오류가 발생했습니다.";
    } else {
      errorMsg = "시스템 오류 발생. 관리자에게 문의해주세요.";
    }

    alert(errorMsg);
    console.log("> ErrorCode : " + errorCode + " , ErrorMessage : " + errorMsg);
  }
}
