import React from "react";
import App from "./App";
import { render } from "react-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

const rootElement = document.getElementById("root");

// 운영에서는 console 로그 제한.
// if (process.env.REACT_APP_MODE === "production") {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>,
  rootElement
);
