// 공통관련 api..
import axios from "axios";

const URL = `${process.env.REACT_APP_API_HOST}/api/`;
const api = axios.create({ baseURL: URL });

const comApi = {
  insertInquiry: (name, email, company, phone, detail, marketingInfo) => {
    const inquiryType = 10; // 메인의 상담하기임.
    let params = {
      name,
      email,
      company,
      phone,
      detail,
      marketingInfo,
      inquiryType,
    };
    return api.post("common/insertInquiry", params);
  },
};

export default comApi;
