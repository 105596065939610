import styled from "styled-components";
import icon_close from "assets/images/icon_close.svg";

export default function AlertPop({ popState, setPopState, popMessage }) {
  return (
    <PopWrapper>
      <CloseBtn
        icon={icon_close}
        onClick={(e) => {
          setPopState((prev) => !prev);
        }}
      ></CloseBtn>

      <TextWrapper id="tab_log">
        <PopDesc>
          MESwiser 엔진의 무료 인공지능 챗봇 생성 용량을 초과 하였습니다. 무료로
          생성 된 챗봇을 사용해보시고 서비스 모델에 가입하시면 추가로 챗봇
          생성이 가능합니다.
        </PopDesc>
      </TextWrapper>
      <LoginBtn
        onClick={(e) => {
          setPopState((prev) => !prev);
        }}
      >
        확인
      </LoginBtn>
    </PopWrapper>
  );
}

export function AlertPop2(props) {
  const { text, callback, popState, setPopState, ...otherProps } = props ?? {};

  return (
    <PopWrapper
      className="pop2"
      {...otherProps}
      style={{ ...(otherProps?.style ?? {}), zIndex: 999999999999 }}
    >
      <CloseBtn
        icon={icon_close}
        onClick={(e) => {
          setPopState((prev) => !prev);
        }}
      ></CloseBtn>

      <TextWrapper>
        <PopDesc>{text}</PopDesc>
      </TextWrapper>

      <AlertPopBtnGroup>
        <AlertPopBtn
          className="confirm"
          onClick={(e) => {
            setPopState((prev) => !prev);
            callback && callback();
          }}
        >
          확인
        </AlertPopBtn>
      </AlertPopBtnGroup>
    </PopWrapper>
  );
}

const PopWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: 45%;
  background-color: #fafafa;

  /* width: 550px; */
  max-width: 550px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  padding: 65px 0 15px;

  border-radius: 20px;

  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.18);

  &.pop2 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    position: fixed;

    @media (max-width: 480px) {
      width: 85%;
    }
  }
`;

const TextWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;
  max-width: 440px;
  margin-bottom: 35px;

  &.list {
    padding: 5px 30px;
    max-height: 500px;
    overflow-y: scroll;
    /* 세로 스크롤바를 작게 만들기 */
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  &.except {
    overflow-y: auto;
    max-width: 100%;
    ::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

const PopDesc = styled.p`
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  margin-top: 10px;
  width: 100%;
  padding: 0 20px;
  word-break: keep-all;

  &.except {
    text-align: center;
  }
`;

const LoginBtn = styled.button`
  text-align: center;
  padding: 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  width: 440px;
  height: 51px;

  border-radius: 4px;
  background-color: #ff892a;
  color: #fff;

  border-radius: 4px;
  margin-bottom: 21px;
`;

const AlertPopBtn = styled(LoginBtn)`
  width: auto;
  height: auto;
  flex: 0 0 auto;
  height: 45px;
  padding: 10px 14px;

  &.cancel {
    background-color: #fff !important;
    color: #ff6347;
    border: 1px solid #ff6347;

    &:hover {
      border: 1px solid #ff8b8b;
      color: #ff8b8b;
    }
  }
`;
export const Box = styled("div")`
  position: relative;
`;

export const FlexBox = styled(Box)`
  display: flex;
`;

const AlertPopBtnGroup = styled(FlexBox)`
  width: 100%;
  justify-content: center;
  margin-top: 25px;

  button {
    margin: 0 5px;
  }
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;

  background: url(${({ icon }) => icon}) no-repeat;
  background-size: cover;
  background-position: center;
`;
