import { useContext } from "react";
import styled from "styled-components";
import NormalButton from "components/atomic/NormalButton";
import icon_Logo from "assets/images/alvis_logo.svg";
import PromoLogo from "assets/images/meswsierPro_logo.png";
import meswiserContent from "assets/images/content04.svg";
import PromoContent01 from "assets/images/content06.svg";
import PromoContent02 from "assets/images/alvis_logo.svg";
import assitLogo from "assets/images/LOGO_Assistant.svg";
import chatLogo from "assets/images/LOGO_Chat.svg";

import { saveAs } from "file-saver";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import SideNavButton from "components/organisms/SideNavButton";

export default function Event202401() {
  const { isMobile } = useContext(isMobileContext);

  const downloadIntroFile = (name) => {
    const fileUrl =
      "/[신청양식] 메스와이저 " + name + "_무료체험_참여신청(신청기관명).hwp";
    const fileName =
      "[신청양식] 메스와이저 " + name + "_무료체험_참여신청(신청기관명).hwp";

    fetch(fileUrl, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, fileName);
      })
      .catch((error) => {
        console.log("Error While donwloading the file:", error);
      });
  };

  return (
    <div>
      <HeaderBackCt>
        <HeaderCt className="header">
          <Logo
            icon_Logo={icon_Logo}
            onClick={(e) => {
              e.preventDefault();
            }}
          />
        </HeaderCt>
      </HeaderBackCt>
      <Wrapper>
        <SideNavButton sideClass={"promo"} />
        <Content className="content">
          <SectionCt>
            <Section className="section01">
              <img className="logo" src={PromoLogo} alt="ProMoLogo" />
              <h2 className="title orange">
                메스와이저(MESwiser)
                <br /> 무료체험 참여 기관 모집
              </h2>
              <p className="desc mid">
                {isMobile ? (
                  <>
                    GPT 기반의 인공지능 솔루션인 메스와이저
                    <br />
                    (MESwiser) 무료 체험을 원하는
                    <br /> 참여 기관을 모집합니다. 차세대 기술 혁신의
                    <br /> 선두에서 메스와이저와 함께라면 업무 역량 강화는
                    <br /> 물론 경쟁력 제고를 강화할 수 있습니다.
                    <br /> 인공지능 도입을 희망하지만 높은 비용이나
                    <br />
                    사용법 미숙으로 인해 또한 어디서부터
                    <br /> 어떻게 시작해야 하는지 막막함으로 인해 고민하셨다면
                    이번 기회를 놓치지 마세요.
                    <br /> 기관, 기업 단체들의 많은 관심 부탁 드리며,주저하지
                    마시고 도전해 보세요.
                  </>
                ) : (
                  <>
                    {" "}
                    GPT 기반의 인공지능 솔루션인 메스와이저(MESwiser) 무료
                    체험을 원하는 참여 기관을 모집합니다. 차세대 기술 혁신의
                    선두에서 메스와이저와 함께라면 업무 역량 강화는 물론 경쟁력
                    제고를 강화할 수 있습니다. 인공지능 도입을 희망하지만 높은
                    비용이나 사용법 미숙으로 인해 또한 어디서부터 어떻게
                    시작해야 하는지 막막함으로 인해 고민하셨다면 이번 기회를
                    놓치지 마세요. 기관, 기업 단체들의 많은 관심 부탁 드리며,
                    주저하지 마시고 도전해 보세요.
                  </>
                )}
              </p>
            </Section>
            <Section className="section06">
              <p className="title headLine">📍 신청 바로가기</p>
              <p className="desc center">
                서식 신청과 링크 신청 중 원하는 방법을 선택해 신청해 주시기
                바랍니다.
              </p>

              <div className="btnCt">
                <NormalButton
                  buttonClass={"mid cOrangeRear round"}
                  buttonTitle={
                    <>
                      챗봇 신청 서식<i class="ri-download-line"></i>
                    </>
                  }
                  callBackFunc={(e) => {
                    e.preventDefault();
                    downloadIntroFile("챗봇");
                  }}
                ></NormalButton>
                <NormalButton
                  buttonClass={"mid cOrange round"}
                  buttonTitle={
                    <>
                      챗봇 신청 링크<i class="ri-external-link-line"></i>
                    </>
                  }
                  callBackFunc={() => {
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSezwOGpNKi1omTzfttvGBn4f3B14kgIJWP1ogqpWkHMTGt1uQ/viewform",
                      "_blank"
                    );
                  }}
                ></NormalButton>
                <NormalButton
                  buttonClass={"mid cOrangeRear round"}
                  buttonTitle={
                    <>
                      어시스턴트 신청 서식
                      <i class="ri-download-line"></i>
                    </>
                  }
                  callBackFunc={(e) => {
                    e.preventDefault();
                    downloadIntroFile("어시스턴트");
                  }}
                ></NormalButton>
                <NormalButton
                  buttonClass={"mid cOrange round"}
                  buttonTitle={
                    <>
                      어시스턴트 신청 링크
                      <i class="ri-external-link-line"></i>
                    </>
                  }
                  callBackFunc={() => {
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSd-cQW0FJCevIlseP-zBJIgJ-zjhjOSFjO2diN_2HMX-YW78A/viewform",
                      "_blank"
                    );
                  }}
                ></NormalButton>
              </div>
            </Section>

            <Section className="section02">
              <h2 className="title2 title">GPT 기반 AI 솔루션 유료 버전</h2>
              <h2 className="title2 title orange">
                6개월 간 무료 업그레이드 기회!
              </h2>

              {isMobile ? (
                <div className="McontentCt">
                  <img src={PromoContent02} alt="커넥트이미지"></img>
                  <div className="contentItem">
                    <img src={assitLogo} alt="어시스턴트로고"></img>
                    <p className="title">
                      <span className="strong">내부 업무</span>에 적합한 <br />{" "}
                      업무 생산성 향상 지원 도구
                    </p>
                  </div>
                  <img src={PromoContent01} alt="커넥트이미지2"></img>
                  <div className="contentItem">
                    <img src={chatLogo} alt="쳇로고"></img>
                    <p className="title">
                      <span className="strong">대외 서비스</span>에 적합한{" "}
                      <br /> GPT 기반의 인공지능 챗봇
                    </p>
                  </div>
                </div>
              ) : (
                <img
                  className="contentImg"
                  src={meswiserContent}
                  alt="메스와이저 소개 이미지"
                ></img>
              )}

              <p className="desc">
                고객상담이나 자료검색에서 데이터 요약과 분석까지 단순 반복
                업무를 줄이고 방대한 데이터에서 원하는 결과를 빠르고 정확하게!
              </p>
              <div className="btnCt">
                <NormalButton
                  buttonClass={"cOrange big round"}
                  buttonTitle={
                    <>
                      메스와이저 공식 홈페이지 가기
                      <i className="ri-external-link-line"></i>
                    </>
                  }
                  callBackFunc={() => {
                    window.open("https://meswiser.ai/", "_blank");
                  }}
                ></NormalButton>
              </div>
            </Section>
            <Section className="section03">
              <p className="title headLine">📍 왜 참여해야 할까요?</p>
              <div className="textCtRow">
                <div className="textItem">
                  <p className="title point">Point 1</p>
                  <div className="textContentCt">
                    <h2 className="title">GPT 기반 AI 토탈 솔루션</h2>
                    <p className="desc center">
                      AI가 선도하는 시대에 발 맞춰 <br /> 최적화된 인공지능
                      <br />
                      솔루션이 필요합니다
                      <br />
                      업무 생산성을 향상시키는
                      <br />
                      메스와이저(MESwiser)로
                      <br />
                      더욱 스마트한 업무 환경을
                      <br />
                      만나실 수 있습니다
                    </p>
                  </div>
                </div>
                <div className="textItem">
                  <p className="title point">Point 2</p>
                  <div className="textContentCt">
                    <h2 className="title">
                      최첨단 IT기술로
                      <br /> 업무 생산성 향상
                    </h2>
                    <p className="desc center">
                      데이터‧AI 활용을 통한 업무의
                      <br />
                      혁신 및 새로운 서비스와 제품
                      <br />
                      창출을 경험할 수 있습니다.
                      <br />
                      메스와이저(MESwiser)가 어떻게
                      {isMobile && <br />} 회사의 역량을 향상시킬 수 있는지
                      <br />
                      확인해 보세요
                    </p>
                  </div>
                </div>
                <div className="textItem">
                  <p className="title point">Point 3</p>
                  <div className="textContentCt">
                    <h2 className="title">
                      실시간 대화형 서비스로
                      <br /> 고객 만족도 제고
                    </h2>
                    <p className="desc center">
                      메스와이저(MESwiser) 챗봇을
                      <br />
                      대외 서비스로 활용 시
                      <br />
                      고객서비스 외 다양한 업무
                      <br />
                      영역을 소화할 수 있습니다
                      <br />
                      실시간 상호작용으로 {!isMobile && <br />} 고객 만족도를
                      {isMobile && <br />} 높이고{!isMobile && <br />} 수집한
                      데이터를 마케팅
                      <br />
                      전략 수립에 적극 활용해 보세요
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </Section>

            <Section className="section04">
              <p className="title headLine">📍 모집 대상 및 방법</p>
              <div className="tableCt">
                <div className="tableRow">
                  <p className="tableTitle">모집대상</p>
                  <p className="tableDesc">
                    GPT기반 AI솔루션 도입을 원하는 기관, 기업, 단체
                  </p>
                </div>
                <div className="tableRow">
                  <p className="tableTitle">사용기간</p>
                  <p className="tableDesc">
                    2024년 4월 1일
                    {isMobile && <br />} ~ 2024년 9월 30일(6개월)
                    <span className="tableDescSmall">*구축기간 2개월 별도</span>
                  </p>
                </div>
                <div className="tableRow">
                  <p className="tableTitle">신청기간</p>
                  <p className="tableDesc">~ 2024년 1월 31일</p>
                </div>
                <div className="tableRow">
                  <p className="tableTitle">신청방법</p>
                  <div className="tableDesc">
                    <div className="tableDescCt">
                      <span className="">1&#41;</span>
                      <p className="">신청 서식 작성 후 메일 제출</p>
                    </div>
                    <div className="tableDescCt">
                      <span className="">2&#41;</span>
                      <p className="">구글폼 신청 제출</p>
                    </div>
                  </div>
                </div>
              </div>
            </Section>

            <Section className="section05">
              <p className="title headLine">📍 선정 기관 혜택 안내</p>
              <p className="desc orderItem">
                <span className="num">1.</span>
                <span className="text">
                  기술 및 학습 지원, 컨설팅 지원 : 2개월
                </span>
              </p>
              <div className="listTextCt">
                <p className="desc orderItem">
                  <span className="num">-</span>
                  {"   "}
                  <span>GPT 기반의 챗봇 및 어시스턴트 활용 방법</span>
                </p>
                <p className="desc orderItem">
                  <span className="num">-</span>
                  {"   "}
                  <span>성능 향상을 위한 GPT 기술 전반</span>
                </p>
                <p className="desc orderItem">
                  <span className="num">-</span>
                  {"   "}
                  <span>성능 향상을 위한 학습데이터 선정 및 정제 방법</span>
                </p>
              </div>
              <p className="desc orderItem">
                <span className="num">2.</span>
                <span>운영 지원 : 6개월</span>
              </p>
              <div className="listTextCt">
                <p className="desc orderItem">
                  <span className="num">-</span>
                  {"   "}
                  <span>
                    협업을 통한 GPT 기반의 챗봇 및 어시스턴트 시범 적용
                  </span>
                </p>
                <p className="desc orderItem">
                  <span className="num">-</span>
                  {"   "}
                  <span>무료 운영 플랜을 통한 솔루션 6개월 무료 체험</span>
                </p>
                <p className="desc orderItem">
                  <span className="num">-</span>
                  {"   "}
                  <span>피드백 분석 및 정식 적용 협의</span>
                </p>
                <p className="desc orderItem">
                  <span className="num">-</span>
                  {"   "}
                  <span>피드백 분석을 통한 성능 향상 협의</span>
                </p>
              </div>
            </Section>
            <Section className="section06">
              <p className="title headLine">📍 신청 바로가기</p>
              <p className="desc center">
                서식 신청과 링크 신청 중 원하는 방법을 선택해 신청해 주시기
                바랍니다.
              </p>

              <div className="btnCt">
                <NormalButton
                  buttonClass={"mid cOrangeRear round"}
                  buttonTitle={
                    <>
                      챗봇 신청 서식<i class="ri-download-line"></i>
                    </>
                  }
                  callBackFunc={(e) => {
                    e.preventDefault();
                    downloadIntroFile("챗봇");
                  }}
                ></NormalButton>
                <NormalButton
                  buttonClass={"mid cOrange round"}
                  buttonTitle={
                    <>
                      챗봇 신청 링크<i class="ri-external-link-line"></i>
                    </>
                  }
                  callBackFunc={() => {
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSezwOGpNKi1omTzfttvGBn4f3B14kgIJWP1ogqpWkHMTGt1uQ/viewform",
                      "_blank"
                    );
                  }}
                ></NormalButton>
                <NormalButton
                  buttonClass={"mid cOrangeRear round"}
                  buttonTitle={
                    <>
                      어시스턴트 신청 서식
                      <i class="ri-download-line"></i>
                    </>
                  }
                  callBackFunc={(e) => {
                    e.preventDefault();
                    downloadIntroFile("어시스턴트");
                  }}
                ></NormalButton>
                <NormalButton
                  buttonClass={"mid cOrange round"}
                  buttonTitle={
                    <>
                      어시스턴트 신청 링크
                      <i class="ri-external-link-line"></i>
                    </>
                  }
                  callBackFunc={() => {
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSd-cQW0FJCevIlseP-zBJIgJ-zjhjOSFjO2diN_2HMX-YW78A/viewform",
                      "_blank"
                    );
                  }}
                ></NormalButton>
              </div>
            </Section>
            <Section className="section07">
              <div className="noticeCt">
                <span className="notice title">[유의사항]</span>
                <div className="notice">
                  <p>
                    <i className="ri-check-fill"></i>
                    <span>
                      무료 체험 기간 후 정식 적용이 필수는 아닙니다. 체험해
                      보시고 고민하시면 됩니다.
                    </span>
                  </p>
                  <p>
                    <i className="ri-check-fill"></i>
                    <span>
                      챗봇은 고객안내 등 대외 서비스에 적합하며, 어시스턴트는
                      내부 업무 지원에 적합한 GPT 기반 인공지능 솔루션입니다.
                    </span>
                  </p>
                  <p>
                    <i className="ri-check-fill"></i>
                    <span>
                      참여 신청 시, 사용 목적에 따라 솔루션을 선택하시면 됩니다.
                      선정되시면 이후에 메스와이저 연구진이 상담해 드릴것이며
                      그때 변경하셔도 됩니다. (중복 지원 불가)
                    </span>
                  </p>
                  <p>
                    <i className="ri-check-fill"></i>
                    <span>
                      참여 기관은 공급기업(주식회사 리스트)의 공식 파트너로서
                      상호 신뢰와 책임 하에 성실하게 임해 주시기를 부탁
                      드립니다.
                    </span>
                  </p>
                  <p>
                    <i className="ri-check-fill"></i>
                    <span>
                      참여 기관과 공급기업은 무료체험 기간 중 상호 획득한 관련
                      자료 및 내부 정보를 공모 외 목적으로 사용할 수 없습니다.
                    </span>
                  </p>
                  <p>
                    <i className="ri-check-fill"></i>
                    <span>
                      신청서 제출 내용이 사실과 다른 경우, 최종 선정된 이후에도
                      참여 자격이 취소될 수 있습니다.
                    </span>
                  </p>
                  <p>
                    <i className="ri-check-fill"></i>
                    <span>
                      최종 선정 결과는 2024년 2월 5일(예정) 메스와이저 공식
                      홈페이지(
                      <a href="https://meswiser.ai/">https://meswiser.ai</a>)
                      공지 및 개별 통보할 예정입니다.
                    </span>
                  </p>
                </div>
              </div>
              <p className="desc center">
                ※ 궁금하신 사항은 이메일{" "}
                <a href="mailto:help@meawiser.ai">help@meswiser.ai</a> 로 문의해
                주세요
              </p>
            </Section>
          </SectionCt>
        </Content>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  button {
    &.big {
      padding: 10px 60px;
    }
    @media (max-width: 480px) {
      font-size: 14px !important;
    }
  }
`;
const Content = styled.div`
  overflow: hidden;
`;

const HeaderBackCt = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background-color: #fff;
  width: 100%;
  flex: 0 0 auto;

  /* box-shadow: 0px -6px 22px 0px #00000040; */
  box-shadow: 0px 0px 22px 0px #00000040;
  z-index: 1;

  padding: 0 25px;

  &.home {
    position: fixed;
    /* padding-top: 64px; */
  }
`;

const HeaderCt = styled.header`
  width: 100%;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 620px) {
    padding: 0 1vw;
  }
`;

const Logo = styled.a`
  margin-right: auto;
  width: 220px;
  height: 34px;
  background: url(${({ icon_Logo }) => icon_Logo}) no-repeat;
  background-size: contain;
  background-position: center left;
  color: transparent;

  cursor: pointer;

  @media (max-width: 480px) {
    width: 165px;
  }
`;

const SectionCt = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  padding: 0 30px;

  @media (max-width: 481px) {
    padding-bottom: 150px;
  }
`;

const Section = styled.section`
  max-width: 1200px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  position: relative;

  padding: 30px 0;

  @media (min-width: 481px) {
    flex-direction: column;
  }

  /* title & text */
  .title {
    font-size: 28px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
    word-break: keep-all;
    text-align: center;
    margin: 8px 0 15px;
    color: #000;
  }
  .title2 {
    font-size: 23px;
    letter-spacing: -2px;
    line-height: 28px;
    margin: 0px;

    @media (min-width: 481px) {
      font-size: 25px;
      letter-spacing: 0px;
      line-height: normal;
    }
  }
  .orange {
    color: #ff892a;
  }
  .headLine {
    width: 100%;
    padding: 30px 0;

    letter-spacing: 1px;

    position: relative;

    @media (min-width: 481px) {
      max-width: 400px;
    }

    &:after {
      content: "";
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 30%;
      left: 0;
      bottom: 20%;
      /* background: #35b395; */
      background: #ffca36;
      border-radius: 10px;
    }
  }

  .point {
    font-size: 22px;
    text-align: left;
    width: 100%;
    color: #ff892a;
    /* @media (min-width: 481px) {
      max-width: 750px;
    } */
  }

  .desc {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    margin: 10px 0 20px;
    word-break: keep-all;
    color: #1a1a1a;
    @media (min-width: 481px) {
      max-width: 715px;
    }
  }

  .desc.mid {
    letter-spacing: -1.5px;
  }
  @media (min-width: 481px) {
    .desc.mid {
      letter-spacing: 0px;
    }
  }
  .center {
    text-align: center;
  }

  .orderItem {
    width: 100%;
    margin: 10px 0;
    font-size: 18px;
    font-weight: 500;
  }

  /* title & text */

  &.section01 {
    .logo {
      width: 100%;
      max-width: 450px;
      margin-bottom: 30px;
    }
    button {
      margin: 16px 0 10px;
    }
  }

  &.section02 {
    .contentImg {
      width: 100%;
      max-width: 650px;
      margin: 30px 0;
    }
    button {
      margin: 10px 0;
    }

    .McontentCt {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      img {
        margin: 15px 0;
      }
      .contentItem {
        border: 1px solid #ff892a;
        border-radius: 40px;
        padding: 20px;
        margin: 10px 5px;
        width: 100%;
        text-align: center;
        img {
          margin: 10px 0;
          width: 100%;
          width: 215px;
        }
        .title {
          font-size: 18px;
          color: #333;
          font-weight: 400;
          margin: 0;
          .strong {
            font-weight: 600;
          }
        }
      }
    }
  }

  &.section03 {
    @media (min-width: 481px) {
      .textCtRow {
        max-width: 1000px;
        display: flex;
      }
      .textItem {
        margin: 0 10px;
        flex: 1 0 30%;
      }
    }
  }
  &.section04 {
    .tableCt {
      @media (min-width: 481px) {
        width: 550px;
      }
      font-size: 15px;
    }
    .tableRow {
      display: flex;
      border: 0.5px solid #efefef;
    }
    .tableTitle {
      flex: 0 0 auto;

      padding: 20px 15px;
      border: 0.5px solid #efefef;
    }
    .tableDesc {
      width: 100%;
      border: 0.5px solid #efefef;
      padding: 20px 15px;
      word-break: keep-all;
    }
    .tableDescSmall {
      font-size: 13px;
      line-height: normal;
    }
    .tableDescCt {
      display: flex;
      line-height: 18px;
      span {
        margin-right: 5px;
      }
      p {
      }
    }
    @media (max-width: 480px) {
      .tableDescSmall {
        display: block;
      }
    }
  }
  &.section05 {
    .orderItem {
      display: flex;
    }
    .listTextCt {
      @media (min-width: 481px) {
        width: 650px;
      }
      .orderItem {
        font-size: 16px;
        font-weight: 400;
        span.num {
          margin: 0 5px;
        }
      }
    }
  }
  &.section06 {
    button {
      margin: 10px 0;
    }
  }

  &.section07 {
    .noticeCt {
      border: 1px solid #efefef;
      padding: 10px 15px;
      color: #0b111e;

      .title {
        display: block;
        text-align: left;
        font-size: 15px;
        margin-bottom: 10px;
      }

      .notice {
        word-break: keep-all;
        /* line-height: 20px; */
        p {
          display: flex;
          margin-bottom: 8px;
          i {
            margin-right: 5px;
            color: #ff892a;
            font-weight: 800;
            font-size: 20px;
          }
          span {
            color: #808080;
            font-size: 15px;
            line-height: 18px;
          }
        }
      }
      a {
        color: #ff892a;
      }
    }

    .desc.center {
      margin: 10px 0;
    }
  }

  /* point text 관련 콘텐츠 */
  .textContentCt {
    border: 5px solid #d3e2f5;
    border-radius: 25px;
    padding: 20px;
    background: #f8faff;
    margin-bottom: 45px;
    min-height: 90%;
    /* 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      font-size: 24px;
      font-weight: 600;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
    }
  }

  /* 버튼 콘테이너 */
  .btnCt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin: 5px;
      flex: 1 1 40%;
      letter-spacing: -1.5px;
      &.mid {
        padding: 8px 12px;
      }
    }
    @media (min-width: 481px) {
      width: 650px;
      justify-content: space-between;
      button {
        margin: 10px;
      }
      button {
        flex: 1 1 40%;
        letter-spacing: 0px;
        &.mid {
          padding: 8px 20px;
        }
      }
    }
  }
`;
