import { useContext, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import BackImg from "../../assets/images/mainSectionBackImg.svg";
import BackImgMobile from "../../assets/images/mainSectionBackImg_mobile.svg";
import content01 from "../../assets/images/content01.png";
import content01Mobile from "../../assets/images/content01-1.png";
import content02 from "../../assets/images/content02.png";
import content03 from "../../assets/images/content03.png";

import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";

import tAsst from "../../assets/images/text_asst.png";
import tChat from "../../assets/images/text_chat.png";

import tabContentImg01 from "../../assets/images/roundImg_01.png";
import tabContentImg02 from "../../assets/images/roundImg_02.png";
import tabContentImg03 from "../../assets/images/roundImg_03.png";
import {
  animated,
  useSpring,
  useSpringRef,
  useTransition,
} from "react-spring/web";

export default function MainContentAdded() {
  const { isMobile } = useContext(isMobileContext);
  const [infoState, setInfoState] = useState("01");

  const transition = useTransition(infoState, {
    config: { duration: 350 },
    from: { y: isMobile ? 10 : -50, opacity: 0 },
    enter: { y: isMobile ? 0 : -45, opacity: 1 },
    leave: { y: isMobile ? 10 : -50, opacity: 0 },
  });

  return (
    <SectionCt backImg={!isMobile ? BackImg : BackImgMobile} className="type03">
      <Section className="type03">
        <p style={{ textAlign: "center" }} className="title">
          유기적인 협업이 필요하신가요?
          <br />
          <span className="title ">
            메스와이저로 {isMobile && <br />}
            업무 통합 환경을 구축해 보세요!
          </span>
        </p>
        <DataTextRowCt className="type03">
          <div
            onClick={(e) => {
              setInfoState("01");
            }}
            className={infoState === "01" ? "infoCt active" : "infoCt"}
          >
            {transition((style) => {
              return (
                <animated.div style={style}>
                  <img
                    className="roundImg"
                    src={tabContentImg01}
                    alt="콘텐츠 이미지01"
                  />
                </animated.div>
              );
            })}

            <p className="title">
              AI 도입
              <span>비용은 절감하고</span>
            </p>

            {!isMobile ? (
              <p className="desc">
                방대한 양의 데이터와 막대한 비용이 발생되는 생성형 AI 도입,
                메스와이저에서는 무료로 체험해볼 수 있습니다
              </p>
            ) : (
              <p className="desc">
                방대한 양의 데이터와 막대한 비용이 발생되는 생성형 AI 도입,
                메스와이저에서는 무료로 체험해볼 수 있습니다
              </p>
            )}
          </div>
          <div
            onClick={(e) => {
              setInfoState("02");
            }}
            className={infoState === "02" ? "infoCt active" : "infoCt"}
          >
            {transition((style) => {
              return (
                <animated.div style={style}>
                  <img
                    className="roundImg"
                    src={tabContentImg02}
                    alt="콘텐츠 이미지02"
                  />
                </animated.div>
              );
            })}

            <p className="title">
              공간에 <span>제약 받지 않고</span>
            </p>

            {!isMobile ? (
              <p className="desc">
                추가된 자료는 실시간으로 AI 엔진이 학습해 PC와 모바일에서 원하는
                결과를 빠르고 정확하게 제공합니다
              </p>
            ) : (
              <p className="desc">
                추가된 자료는 실시간으로 AI 엔진이 학습해 PC와 모바일에서 원하는
                결과를 빠르고 정확하게 제공합니다
              </p>
            )}
          </div>
          <div
            onClick={(e) => {
              setInfoState("03");
            }}
            className={infoState === "03" ? "infoCt active" : "infoCt"}
          >
            {transition((style) => {
              return (
                <animated.div style={style}>
                  <img
                    className="roundImg"
                    src={tabContentImg03}
                    alt="콘텐츠 이미지03"
                  />
                </animated.div>
              );
            })}

            <p className="title">
              업무 관리는 <span> 효율적으로</span>
            </p>

            <p className="desc">
              데이터 기반 의사 결정을 위해 필요한 정보를 찾고, 활용하는 시간을
              획기적으로 줄여줍니다
            </p>
          </div>
        </DataTextRowCt>
      </Section>
    </SectionCt>
  );
}

export function MainContentAdded2() {
  const { isMobile } = useContext(isMobileContext);

  return (
    <SectionCt className="type02 white">
      <Section className="type02">
        <FelxColumnCt className="type02">
          <Title
            style={{ marginBottom: "30px" }}
            className={isMobile ? "Title type02 " : "Title center"}
          >
            유기적으로 연결되어 있는
            <br />
            <span className="orange">
              GPT기반 인공지능 토탈솔루션을 {isMobile && <br />} 경험해 보세요
            </span>
          </Title>

          <img className="content01" src={content01} alt={"img"}></img>
          <ContentCt>
            <img className="mainimg" src={content01Mobile} alt={"img"}></img>
            <div className="flexBox">
              <div>
                <p className="titleImg">
                  <img src={tChat} alt="logo"></img>
                </p>
                <div className="textBox">
                  <p>대화 인터페이스용 챗봇</p>
                  <p>GPT기반 인공지능</p>
                  <p>고객 응대, 브랜드 제고</p>
                </div>
              </div>
              <div>
                <p className="titleImg">
                  <img src={tAsst} alt="logo"></img>
                </p>
                <div className="textBox">
                  <p>업무생산성 향상 도구</p>
                  <p>GPT기반 인공지능</p>
                  <p>대량의 복잡한 지식 관리 및 활용</p>
                </div>
              </div>
            </div>
          </ContentCt>
        </FelxColumnCt>
      </Section>
    </SectionCt>
  );
}

export function MainContentAdded3() {
  const { isMobile } = useContext(isMobileContext);
  const [tabState, setTabState] = useState("01");

  const [imgState, setImgState] = useState(1);

  const handleTransitionImg = () => {
    setImgState((prev) => (prev + 1) % 2);
    // setImgState((prev) => tabState);
  };
  const transRef = useSpringRef();

  const transitions = useTransition(imgState, {
    ref: transRef,
    keys: null,
    config: { duration: 150 },
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-100%,0,0)" },
  });

  useEffect(() => {
    transRef.start();
  }, [imgState]);

  return (
    <SectionCt className="type06">
      <Section className="type06">
        <Title
          style={{ marginBottom: "30px" }}
          className={isMobile ? "Title type02 " : "Title center"}
        >
          체계적인 업무 관리, 효율적인 협업까지
          <br />
          <span className="orange">메스와이저 하나면 됩니다</span>
        </Title>

        <div className="tabBtnCt">
          <button
            className={tabState === "01" ? "active" : ""}
            onClick={(e) => {
              setTabState("01");
              handleTransitionImg();
            }}
          >
            챗봇
            <span>(Chatbot)</span>
          </button>
          <button
            className={tabState === "02" ? "active" : ""}
            onClick={(e) => {
              setTabState("02");
              handleTransitionImg();
            }}
          >
            어시스턴트<span>(Assistant)</span>
          </button>
        </div>
        <div className="tabContent">
          {transitions((style, i) => {
            return (
              <>
                <animated.div
                  style={style}
                  className={
                    tabState === "01" ? " contentCt active" : " contentCt"
                  }
                >
                  <img alt="" src={content02}></img>
                </animated.div>

                <animated.div
                  style={style}
                  className={
                    tabState === "02" ? " contentCt active" : " contentCt"
                  }
                >
                  <img alt="" src={content03}></img>
                </animated.div>
              </>
            );
          })}

          {/* <div
            className={
              tabState === "01" ? "free contentCt active" : "free contentCt"
            }
          >
            <img alt="" src={content02}></img>
          </div>
          <div
            className={
              tabState === "02" ? "charge contentCt active" : "charge contentCt"
            }
          >
            <img alt="" src={content03}></img>
          </div> */}
        </div>
      </Section>
    </SectionCt>
  );
}

const Up = keyframes`
    0% {
      transform: translateY(-10%) scale(1.2);

    }
    100% {
      transform: translateY(0%) scale(1);
      opacity: 1;
    }
`;

const SectionCt = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    padding: 0 30px;
  }
  &.type01 {
    background: #fff;
  }
  &.type02 {
    background: #f7f9ff;
    padding: 100px 0;

    @media (max-width: 480px) {
      padding: 0;
    }
  }
  &.type03 {
    background: url(${({ backImg }) => backImg}) no-repeat;
    background-size: cover;
  }
  &.type04 {
    background: #fff;
  }
  &.type05 {
    background: #fff;
  }
  &.type06 {
    padding-top: 90px;
    background: #fff;
  }
  &.type07 {
    background: linear-gradient(0deg, #ffca36 0%, #ffca36 100%), #fff;

    @media (max-width: 480px) {
      padding: 0 20px;
    }
  }
`;

const Section = styled.section`
  max-width: 1200px;
  min-height: 742px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d0d0d;

  @media (max-width: 1000px) {
    flex: 0 1 auto;
    margin-top: 100px;
  }

  &.type01 {
    flex-wrap: wrap;
    min-height: auto;

    & img {
      //width: 407px;
      width: auto;
      height: 608px;
      margin-right: 70px;
      @media (max-width: 1000px) {
        margin-bottom: 100px;
      }
      @media (max-width: 900px) {
        margin: 0 auto;
      }
      @media (max-width: 480px) {
        width: 100%;
        height: auto;
        //padding: 0 5px;
      }
    }
    .Title {
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }
    .Title2 {
      font-size: 25px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 16px;
      word-break: keep-all;
    }
    .Desc {
      color: #1a1a1a;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.18px;
      margin-bottom: 32px;
    }

    @media (max-width: 480px) {
      .Title {
        color: #1a1a1a;
        font-size: 26px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        text-align: center;
      }
      .Title2 {
        color: #1a1a1a;
        font-size: 18px;
        line-height: normal;
        letter-spacing: -0.28px;
        margin-bottom: 20px;
        text-align: center;
      }
      .Desc {
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.16px;
        text-align: center;
      }
    }

    button {
      width: 232px;
      height: 56px;
      padding: 10px;
      border-radius: 50px;
      font-size: 30px;

      @media (max-width: 1000px) {
        flex: 1 0 auto;
        margin: 0 auto;
      }
      @media (max-width: 480px) {
        min-width: 280px;
        height: 56px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
      }
    }
  }
  &.type02 {
    flex-wrap: wrap;
    img {
      &.content01 {
        display: none;
      }
      @media (min-width: 1001px) {
        &.content01 {
          display: block;
        }
      }
      width: 100%;
      /* margin: 0 36px; */
    }

    @media (max-width: 480px) {
      margin-top: 0px;
    }
    .title {
      &.highlight {
        z-index: 0;
        position: relative;
        &::after {
          z-index: -1;
          content: "";
          width: 60%;
          height: 53%;
          position: absolute;
          background: rgba(255, 206, 70, 0.81);
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }

        @media (max-width: 480px) {
          font-size: 24.5px;
          width: 105%;
          &::after {
            width: 100%;
          }
        }

        @media (max-width: 559px) and (min-width: 481px) {
          font-size: 30px;
          letter-spacing: -0.5px;
          &::after {
            width: 100%;
          }
        }
        @media (max-width: 629px) and (min-width: 560px) {
          font-size: 36px;
          letter-spacing: -0.5px;
          &::after {
            width: 100%;
          }
        }
        @media (max-width: 800px) and (min-width: 630px) {
          &::after {
            width: 90%;

            letter-spacing: -0.5px;
          }
        }

        @media (max-width: 1000px) and (min-width: 801px) {
          &::after {
            width: 80%;
            height: 53%;
          }
        }
      }
    }
    /* 추가 콘텐츠 */
    &.Added {
      flex-direction: column;
      & img.contentImg {
        width: 80%;
        margin-bottom: 50px;
      }
    }

    @media (max-width: 480px) {
      img.alvisImg {
        margin: 0 0 30px 0;
      }
      .title {
        color: #1a1a1a;
        font-size: 26px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1px;
        margin-bottom: 50px;
      }
    }
  }
  &.type03 {
    flex-wrap: wrap;
    flex-direction: column;
    min-height: fit-content;
    margin: 80px 0 120px;

    @media (max-width: 1000px) {
      align-items: center;
      justify-content: center;
      .title {
        text-align: center;
        word-break: break-word;
        width: 70%;
      }
    }

    @media (max-width: 480px) {
      margin: 50px 0 70px;
    }

    .title {
      color: #000;
      font-size: 40px;
      /* font-weight: 700; */
      line-height: normal;
      letter-spacing: -0.4px;
      margin-top: 50px;
      margin-bottom: 80px;
      & span {
        font-weight: 700;
      }

      @media (max-width: 480px) {
        font-size: 24px;
        letter-spacing: -1px;
        margin-bottom: 50px;
        width: 100%;
        & span {
          font-weight: 700;
        }
      }

      & span.orange {
        color: #ff892a;
      }
    }

    .infoCt {
      transition: 0.2s;
      display: flex;
      width: 340px;
      height: 320px;
      padding: 35px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 47px;

      margin: 0 10px 15px;
      background: #fff6cf;

      flex: 0 0 31%;
      border: 2px solid transparent;

      cursor: pointer;
      &:hover {
        opacity: 0.9;
        scale: 1.05;
      }
      &.active {
        background: #fff;
        border: 2px solid #c8c9cc;
        /* box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26); */
        position: relative;

        & .title {
          & span {
            color: #ff892a;
          }
        }
        & .roundImg {
          display: block;
        }
      }

      .roundImg {
        display: none;
        /* width: 100px; */
        height: 130px;
        position: absolute;
        left: 50%;
        top: -80px;
        transform: translateX(-50%);
      }
      .title {
        color: #1a1a1a;
        text-align: center;
        font-size: 27px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.34px;
        word-break: keep-all;
        margin: 25px 0;
        /* margin: auto 0; */

        min-height: 100px;

        display: flex;
        align-items: center;
        justify-content: center;

        & span {
          margin-left: 5px;
        }
      }
      .desc {
        color: #1a1a1a;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;

        min-height: 100px;

        word-break: keep-all;
      }

      @media (max-width: 1000px) {
        margin-bottom: 30px;
        padding: 10px 20px;
        width: 300px;
        height: 320px;
        & .title {
          display: block;
          margin-top: 10px;
          font-size: 24px;
          margin: 10px 0;
          letter-spacing: -1.5px;
        }
        & .desc {
          font-size: 16px;
        }
      }

      @media (max-width: 480px) {
        flex: 1 0 100%;
        width: 280px;
        height: 225px;
        padding: 20px 15px;

        .title {
          display: flex;
          color: #1a1a1a;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.26px;
        }
        .desc {
          font-size: 16px;
        }

        .roundImg {
          display: none;
          /* width: 100px; */
          height: 100px;
          position: absolute;
          left: 50%;
          top: -50px;
          transform: translateX(-50%);
        }
      }
    }
  }
  &.type04 {
    min-height: 900px;
    //margin-top: 100px;
    margin: 100px 0;
    flex-wrap: wrap;
    @media (max-width: 1000px) {
      height: auto;
    }
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }
  &.type05 {
    height: 600px;
    min-height: unset;
    padding-top: 60px;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: 480px) {
      padding-top: 10px;
      margin: 80px 0;
    }

    & p.line {
      width: 533px;
      height: 1px;
      background: #000;
      margin-bottom: 38px;
    }
    & p.title {
      color: #1a1a1a;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 18px;
    }
    & p.desc {
      color: #969595;
      text-align: center;
      font-size: 30px;
      font-weight: 350;
      line-height: normal;
      letter-spacing: 0.75px;
      margin-bottom: 120px;
    }

    @media (max-width: 1000px) {
      height: auto;
      & p.line {
        width: 80%;
      }

      & p.title,
      & p.desc {
        word-break: keep-all;
      }
      & p.title {
        font-size: 36px;
        width: 70%;
      }

      & p.desc {
        font-size: 24px;
        width: 55%;
      }
    }
    @media (max-width: 480px) {
      & p.title {
        width: 100%;
        color: #232323;
        font-size: 28px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
      }
      & p.desc {
        width: 100%;
        font-size: 20px;
        font-weight: 350;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-bottom: 40px;
      }
    }
  }
  &.type06 {
    flex-direction: column;
    margin-bottom: 125px;
    flex-wrap: wrap;

    align-items: unset;

    @media (max-width: 1000px) {
      margin-top: 0;
      min-height: unset;
    }

    .title {
      word-break: keep-all;
      color: #1a1a1a;
      text-align: center;
      font-size: 40px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 41px;
      .strong {
        font-weight: bold;
      }
      @media (max-width: 1000px) {
        width: 85vw;
        font-size: 34px;
      }
      @media (max-width: 480px) {
        font-size: 26px;
        letter-spacing: -2px;
        .strong {
        }
      }
    }
    .tabBtnCt {
      /* width: 539px; */
      /* background: #e7ebf9; */

      width: 100%;
      margin-bottom: 61px;
      border-radius: 87px;
      display: flex;
      /* justify-content: space-between; */
      justify-content: center;
      @media (max-width: 1000px) {
        font-size: 32px;
        /* width: 40%; */
      }
      @media (max-width: 480px) {
        /* width: 335px; */
        width: 100%;
        height: 79px;
        margin-bottom: 40px;
      }
      button {
        font-size: 30px;
        line-height: normal;
        letter-spacing: -0.34px;
        margin: 9px 0px 10px;
        padding-bottom: 15px;
        color: rgba(0, 0, 0, 0.4);
        padding: 8px 10px;
        border-bottom: 10px solid rgba(0, 0, 0, 0.2);

        flex: 0 0 45%;
        max-width: 400px;

        transition: 0.1s;

        @media (max-width: 1000px) {
          padding: 8px 20px;
        }
        @media (max-width: 480px) {
          padding: 8px 5px;
          font-size: 16px;
          flex: 0 0 auto;
          margin: 9px 0;
          width: 149px;
          height: 61px;
          line-height: 16px;
          border-bottom: 5px solid rgba(0, 0, 0, 0.2);
        }
        span {
          font-size: 0.8em;
          letter-spacing: -1px;
          @media (max-width: 480px) {
            font-size: 0.5em;
          }
        }
      }
      button.active {
        padding: 8px 10px;
        color: #ff892a;
        /* padding-bottom: 15px; */
        border-bottom: 10px solid #ff892a;
        font-weight: 700;

        @media (max-width: 1000px) {
          padding: 8px 20px;
        }
        @media (max-width: 480px) {
          border-bottom: 5px solid #ff892a;
        }
      }
    }
    .tabContent {
      display: flex;
      justify-content: center;

      @media (min-width: 481px) {
        height: 660px;
      }
      .contentCt {
        transition: 0.2s;
        /* width: 463px; */
        width: 80%;
        /* min-height: 370px; */
        margin: 0 15.5px;

        position: relative;

        border-radius: 20px;
        background: #e7ebf9;

        @media (min-width: 1000px) {
          width: 900px;
        }

        & img {
          width: 100%;
          padding: 15px;
        }
        display: none;
        .header {
          border-bottom: 1px solid #fff;
        }

        &.active {
          display: block;
          border-radius: 20px;
          background: transparent;
          /* box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26); */
          .header {
            border-bottom: 1px solid #6d96ff;
            position: relative;
            &::after {
              position: absolute;
              content: "";
              width: 24px;
              height: 24px;
              top: 50%;
              left: 32%;
              transform: translate(0, -50%);
              border-radius: 100%;

              background-position: center;
              background-size: 12px;
              background-color: #ff892a;
            }
          }
        }

        .header {
          height: 80px;
          color: #1a1a1a;
          font-size: 25px;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .unit {
          margin: 20px 0px 0px 40px;
          color: #1a1a1a;
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -1px;
          height: 20px;
          display: flex;
          align-items: center;
        }
        .desc.hasSpan {
          flex-direction: column;
          align-items: start;
          @media (max-width: 480px) {
            position: relative;
          }
        }
        .desc {
          margin: 6px 45px 6px 70px;
          color: #1a1a1a;
          font-size: 18px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -1px;
          height: 40px;
          display: flex;
          align-items: center;

          position: relative;

          &.letterSpacing {
            letter-spacing: -0.5px;
          }

          @media (max-width: 480px) {
            margin-left: 45px;
            margin-right: 15px;
          }

          & span {
            font-size: 13px;
          }
          &::after {
            position: absolute;

            background-position: center;
            background-size: contain;
            content: "";
            width: 24px;
            height: 24px;
            top: 50%;
            left: -44px;
            transform: translate(50%, -50%);
          }

          &.lineTwo {
            line-height: inherit;
          }

          &.last {
            margin-bottom: 35px;
          }
        }
        .descSpecial {
          position: absolute;
          bottom: 15px;
          font-size: 16px;
          color: #ff893a;
          width: 100%;
          text-align: center;
        }

        @media (max-width: 1000px) {
          display: none;
          &.active {
            display: block;
          }
        }
        @media (max-width: 480px) {
          height: auto;
        }
      }
      @media (max-width: 480px) {
        .contentCt {
          width: 335px;

          &.free {
            /* height: 260px; */
            & p.desc.last {
              margin-bottom: 35px;
            }
            & .descSpecial {
              bottom: 10px;
              font-size: 14px;
            }
          }
        }
        .header {
          font-size: 24px !important;
          padding-left: 32px;
        }
        .desc {
          font-size: 15px !important;
          word-break: keep-all;
          letter-spacing: -1px !important;
        }
      }
    }
  }
  &.type07 {
    min-height: 245px;
    text-align: center;
    background: #ffca36;
    display: flex;

    @media (max-width: 1000px) {
      width: 100%;
      height: auto;
      min-height: 512px;
      margin-top: 0;
      flex-direction: column;
      justify-content: space-around;
    }

    @media (max-width: 480px) {
      justify-content: space-around;
    }

    @media (max-width: 1100px) and (min-width: 1000px) {
      flex-wrap: wrap;
    }

    & .infoCt {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 0 !important;

      height: 150px;
      width: 540px;

      @media (max-width: 1090px) {
        &.first {
          padding-right: 0 !important;
        }
      }
      @media (min-width: 481px) and (max-width: 1000px) {
        &.first {
          padding-right: 20px !important;
        }
      }

      @media (max-width: 1080px) and (min-width: 1000px) {
        &.first {
          border-bottom: 1px solid #000;
          border-right: none !important;
          padding-bottom: 25px;
          margin-bottom: 30px;
          position: relative;
        }

        &.last {
          padding-left: 0px !important;
          margin-bottom: 30px;
        }
      }
      @media (max-width: 1000px) {
        width: 100%;
        height: 200px;
        flex-direction: column;
        padding: 0 20px;
        & .text {
          margin-bottom: 32px;
          margin-left: 20px;
          width: 100%;
        }
        & button {
          align-self: end;
        }
      }

      @media (max-width: 480px) {
        padding: 0;
      }

      & .text {
        color: #1a1a1a;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
        text-align: left;
        margin-right: 10px;
        strong {
          font-weight: bold;
        }

        @media (max-width: 480px) {
          font-size: 22px;
        }
      }
      &.first {
        border-right: 1px solid #000;
        padding-right: 25px;
        margin-bottom: 30px;
        position: relative;

        @media (max-width: 1000px) {
          border-right: none;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: #000;
            bottom: -30px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }
      &.last {
        padding-left: 25px;
        margin-bottom: 30px;
      }
      &.first,
      &.last {
        @media (max-width: 1000px) {
          justify-content: center;
        }

        @media (max-width: 480px) {
          padding: 0;

          button {
            width: 220px;
            height: 60px;
            font-size: 22px;
          }
        }
      }
      button {
        transition: 0.2s;
        color: #1a1a1a;
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        line-height: normal;
        border-radius: 52px;
        background: #fff;
        letter-spacing: -0.22px;
        width: 200px;
        height: 60px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        &:hover {
          background: #fda055;
          color: #ffffff;
        }
      }
    }
  }
`;

const DataTextRowCt = styled.div`
  margin-bottom: 82px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media (max-width: 480px) {
    &.type02.mobile {
      margin-bottom: 0;
    }
  }

  &.alvisFlow {
    justify-content: flex-start;
  }

  &.type03 {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  &.type04 {
    margin-bottom: 0;

    @media (max-width: 1100px) and (min-width: 1000px) {
      flex-wrap: wrap;
    }
  }

  &.type05 {
    width: 1000px;
    text-align: center;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 480px) {
      width: unset;
      margin-bottom: 0;
      padding: 0;

      & img.part {
        margin: 10px;
        height: 25px;
      }
    }
    & img.part {
      margin: 15px;
    }
  }
  & img.part {
    margin-right: 94px;
    height: 35px;
  }

  &.mobile {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.p`
  width: 100%;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  margin-bottom: 79px;
  word-break: keep-all;

  @media (max-width: 480px) {
    padding: 0 20px;
  }

  &.hasImg {
    & img {
      width: 240px;
    }
    margin: 30px 0px;
    @media (max-width: 480px) {
      margin-bottom: 0;
    }
  }

  &.type02 {
    letter-spacing: -0.4px;
    font-size: 30px;
    line-height: 45px;

    &.Added {
      /* margin-top: 50px; */
      color: #333;
      text-align: center;
      font-size: 26px;
      font-weight: 300;
      .strong {
        font-weight: 700;
      }

      &.orange {
        color: #ff892a;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
      }

      &.Desc {
        color: #333;
        text-align: center;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        & span {
          font-weight: 700;
        }
        @media (max-width: 480px) {
          font-size: 18px !important;
          line-height: 24px;
        }
      }
    }

    @media (max-width: 480px) {
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1px;

      .strong {
        font-size: 40px;
      }
      .desc {
        display: inline-block;
        margin-top: 8px;
        font-size: 26px;
        font-weight: 400;
        letter-spacing: -1.5px;
      }
    }
  }
  &.type04 {
    @media (max-width: 480px) {
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
      margin-bottom: 30px;
    }
  }
  & span.orange {
    color: #ff892a;
  }
`;

const FelxColumnCt = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  &.scActive {
    animation: ${Up} 0.7s ease-in-out;
    animation-fill-mode: forwards;
  }
  &.Ani01 {
    animation-delay: 0.3s;
  }
  &.Ani02 {
    animation-delay: 0.6s;
  }
  &.Ani03 {
    animation-delay: 0.9s;
  }
  &.Ani04 {
    animation-delay: 1.2s;
  }

  &.type02 {
    img.content {
      margin: 0 auto;
      width: 80%;
    }
  }
`;

const ContentCt = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      &.mainimg {
        width: 30vw;
        margin-bottom: 20px;
      }
      @media (max-width: 480px) {
        &.mainimg {
          width: 50%;
        }
      }
    }
    .flexBox {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      & div {
        margin: 10px;
        height: 100%;
        flex: 0 0 35%;
        p.titleImg {
          text-align: center;
          width: 100%;
          & img {
            width: 65%;
            max-height: 30px;
          }
        }

        @media (max-width: 480px) {
          & div {
            margin: 10px 20px;
          }
          p.titleImg {
            & img {
              width: 100%;
            }
          }
        }

        & div.textBox {
          border-radius: 10px;
          border: 2px solid #ffcc00;
          background: #fff;

          font-weight: 600;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          & p {
            height: 60px;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            padding: 15px;
            border-bottom: 1px solid #dcdcdc;
            font-size: 14px;
            word-break: keep-all;
            &:last-child {
              border-bottom: none;
            }

            @media (max-width: 480px) {
              font-size: 12px;
              letter-spacing: -1px;
              line-height: normal;
            }
          }
        }
      }
    }
  }
`;
