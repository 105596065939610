import styled, { css, keyframes } from "styled-components";

import iconStart from "../../assets/images/icon_start.svg";
import iconSetting from "../../assets/images/icon_settingWhite.svg";

export default function NormalButton({
  disabled,
  buttonId,
  buttonTitle,
  buttonClass,
  callBackFunc,
  type,
  style,
  setMyalvisBtn,
  icon,
}) {
  return (
    <NormalBtn
      type={type}
      id={buttonId ? buttonId : "submit_btn"}
      disabled={disabled}
      className={buttonClass ? buttonClass : ""}
      style={{ ...style }}
      onClick={(e) => {
        e.preventDefault();
        callBackFunc && callBackFunc(e);
      }}
      icon={icon}
    >
      {buttonTitle}
    </NormalBtn>
  );
}

export const NormalBtn = styled.button`
  min-width: fit-content;
  transition: 0.2s;

  letter-spacing: -1px;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid #999;
  background: #fff;

  &.small {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    .mobile {
      font-size: 12px;
    }
  }
  &.mid {
    padding: 8px 20px;
    font-size: 16px;
    line-height: 28px;
    .mobile {
      font-size: 14px;
    }
  }
  &.big {
    padding: 15px 80px;
    font-size: 22px;
    line-height: 28px;
    .mobile {
      font-size: 16px;
      max-width: 240px;
    }
  }

  &.round {
    border-radius: 100px;
  }

  &.cOrange {
    color: #fff;
    background: #ff892a;
    border: 1px solid transparent;
  }
  &.cOrangeRear {
    color: #ff892a;
    background: #fff;
    border: 1px solid #ff892a;
  }
  &.cDel {
    color: #808080;
    background: #fff;
    border: 1px solid #808080;
  }
  &.cGreen {
    color: #fff;
    background: #19c37d;
    border: 1px solid transparent;
  }

  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.8;
  }
`;
