import NormalButton from "components/atomic/NormalButton";
import { saveAs } from "file-saver";
import { useContext, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

import MainContentAdded, {
  MainContentAdded2,
  MainContentAdded3,
} from "./MainContentAdded";

import iconCheckOrange from "../../assets/images/icon_checkOrange.svg";
import iconCheckWhite from "../../assets/images/icon_checkWhite.svg";
import iconDownArrow from "../../assets/images/icon_downArrow.svg";

import Advice from "components/pages/consult/Advice";
import { AlertPop2 } from "components/templates/popup/AlertPop";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";

import mainBg from "../../assets/images/BG_main.png";
import main from "../../assets/images/Main_01.png";
// import main from "../../assets/images/Main_total.png";

import mainSubimg1 from "../../assets/images/main_sub01.png";
import mainSubimg2 from "../../assets/images/main_sub02.png";

import mAnal from "../../assets/images/icon_Manal.png";
import mAsst from "../../assets/images/icon_Masst.png";
import mChat from "../../assets/images/icon_Mchat.png";
import mClean from "../../assets/images/icon_Mclean.png";
import mCreate from "../../assets/images/icon_Mcreate.png";
import mMarket from "../../assets/images/icon_Mmarket.png";
import mRich from "../../assets/images/icon_Mrichdata.png";
import mScrap from "../../assets/images/icon_Mscrap.png";
import mSearch from "../../assets/images/icon_Msearch.png";

import iconMove from "../../assets/images/icon_move.png";

import mainChatImg from "../../assets/images/main_ChatImg.png";
import mainAsstImg from "../../assets/images/main_AssImg.svg";

import suitLogoAnal from "../../assets/images/suitLogo_anal.png";
import suitLogoAsst from "../../assets/images/suitLogo_asst.png";
import suitLogoChat from "../../assets/images/suitLogo_chat.png";
import suitLogoClean from "../../assets/images/suitLogo_clean.png";
import suitLogoCreate from "../../assets/images/suitLogo_create.png";
import suitLogoMarket from "../../assets/images/suitLogo_market.png";
import suitLogoRich from "../../assets/images/suitLogo_rich.png";
import suitLogoScrap from "../../assets/images/suitLogo_scrap.png";
import suitLogoSearch from "../../assets/images/suitLogo_search.png";

// 스와이퍼 삽입 부분
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { useSpring, animated } from "react-spring/web";
import useIntersectionObserver from "utils/useInterSectionObserver";
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function MainContent({ firstComp, secondComp }) {
  const [infoState, setInfoState] = useState("0");
  const [adviceToggle, setAdviceToggle] = useState(null);
  const { isMobile } = useContext(isMobileContext);

  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [subImgActive, setSubImgActive] = useState("none");

  const scrollContentRef = useRef();
  const chatbotUrl = `${process.env.REACT_APP_CHATBOT_HOST}`;
  const asstbotUrl = `${process.env.REACT_APP_ASSISTANT_HOST}`;

  const pageList = [
    {
      title: "메스와이저 챗봇",
      desc: ["GPT기반 대화형 챗봇으로 고객 응대와 브랜드 제고에 활용하세요"],
      pageActive: true,
      url: chatbotUrl,
      tImg: suitLogoChat,
      logo: mChat,
    },
    {
      title: "메스와이저 어시스턴트",
      desc: [
        "대량의 지식 관리를 GPT기반 업무",
        "생산성 향상 도구로 손쉽게 활용해 보세요",
      ],
      pageActive: true,
      url: asstbotUrl,
      tImg: suitLogoAsst,
      logo: mAsst,
    },
    {
      title: "메스와이저 스크래퍼",
      desc: ["웹 지식 수집 도구를 통해 원하는", "사이트를 수집해 보세요"],
      pageActive: false,
      url: "",
      tImg: suitLogoScrap,
      logo: mScrap,
    },
    {
      title: "메스와이저 서처",
      desc: [
        "하이브리드 검색 엔진이 GPT기반,",
        "키워드 기반 검색 환경을",
        "스마트하게 지원합니다",
      ],
      pageActive: false,
      url: "",
      tImg: suitLogoSearch,
      logo: mSearch,
    },
    {
      title: "메스와이저 애널라이저",
      desc: [
        "GPT기반 분석 도구를 통해 웹 및",
        "보유 지식 분석을 편하게 시작하세요",
      ],
      pageActive: false,
      url: "",
      tImg: suitLogoAnal,
      logo: mAnal,
    },
    {
      title: "메스와이저 크리에이터",
      desc: [
        "GPT기반 창작 도구를 통해",
        "카피라이트, 보고서, 기사, 블로그를 편하게 작성해 보세요",
      ],
      pageActive: false,
      url: "",
      tImg: suitLogoCreate,
      logo: mCreate,
    },
    {
      title: "메스와이저 마케터",
      desc: [
        "웹 및 보유 지식 분석을 통한",
        "레포팅을 GPT기반 시장조사 도구로 완성해 보세요",
      ],
      pageActive: false,
      url: "",
      tImg: suitLogoMarket,
      logo: mMarket,
    },
    {
      title: "메스와이저 클리너",
      desc: [
        "내용 수정 및 교정을 GPT기반 문서",
        " 정제 도구로 한 번에 해결하세요",
      ],
      pageActive: false,
      url: "",
      tImg: suitLogoClean,
      logo: mClean,
    },
    {
      title: "리치데이타",
      desc: ["학습 데이터 구축 도구로 파인튜닝용 학습 데이터를 구축해 보세요"],
      pageActive: true,
      url: "https://richdata.kr/",
      tImg: suitLogoRich,
      logo: mRich,
    },
  ];

  const swiperContent = [
    {
      desc01: "나의 AI 업무 지원 파트너, 메스와이저",
      mainTitle: "업무 생산성을 무한대로!",
      strong: "메스와이저(MESwiser)",
      desc02: "GPT 기반 인공지능 토탈 솔루션 메스와이저로",
      desc03: "업무 프로세스를 개선하세요",
      img01: main,
      img02: mainSubimg1,
      img03: mainSubimg2,
      btn01: "CHATBOT 바로가기",
      btn02: "CHATBOT 소개서 받기",
      url: chatbotUrl,
      name: "Chatbot",

      btn03: "ASSISTANT 바로가기",
      btn04: "ASSISTANT 소개서 받기",
      url2: asstbotUrl,
      name2: "Assistant",
    },
    {
      desc01: "나의 AI 업무 지원 파트너, 메스와이저",
      mainTitle: "홈페이지 주소만 입력하세요",
      strong: "메스와이저 챗봇",
      desc02: "메스와이저가 GPT기반",
      desc03: "인공지능 챗봇을 무료로 만들어 드립니다",
      img01: mainChatImg,
      img02: "",
      img03: "",
      btn01: "CHATBOT 바로가기",
      btn02: "CHATBOT 소개서 받기",
      url: chatbotUrl,
      name: "Chatbot",
    },
    {
      desc01: "나의 AI 업무 지원 파트너, 메스와이저",
      mainTitle: "효율적인 업무 관리의 모든 것",
      strong: "메스와이저 어시스턴트",
      desc02: "AI엔진이 방대한 문서도 한 번에 학습!",
      desc03: "업무용 GPT 메스와이저 어시스턴트를 시작하세요",
      img01: mainAsstImg,
      img02: "",
      img03: "",
      btn01: "ASSISTANT 바로가기",
      btn02: "ASSISTANT 소개서 받기",
      url: asstbotUrl,
      name: "Assistant",
    },
  ];

  // 홈페이지 인트로 이미지 에니메이션 조절 ref
  const imgref01 = useRef();
  const imgref02 = useRef();
  const emptyRef = useRef();

  const downloadIntroFile = (url, name) => {
    const fileUrl = "/MESwiser_" + name + "소개서.pdf";
    const fileName = "MESwiser_" + name + "소개서.pdf";

    fetch(fileUrl, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, fileName);
      })
      .catch((error) => {
        console.log("Error While donwloading the file:", error);
      });
  };

  // 애니메이션 효과!! 모음
  // 메인 컴포넌트 애니메이션
  useEffect(() => {}, []);
  const Handleanimation = () => {
    setTimeout(() => {
      imgref01.current.classList.add("active");
    }, 750);

    setTimeout(() => {
      imgref02.current.classList.add("active");
    }, 1000);
  };

  // 하위 콘텐츠 설정
  const triggerRef1 = useRef();
  const triggerRef2 = useRef();
  const triggerRef3 = useRef();
  const triggerRef4 = useRef();
  const triggerRef5 = useRef();

  // 하위 콘텐츠 설정
  const dataRef1 = useIntersectionObserver(triggerRef1, {
    freezeOnceVisible: true,
  });
  const dataRef2 = useIntersectionObserver(triggerRef2, {
    freezeOnceVisible: true,
  });
  const dataRef3 = useIntersectionObserver(triggerRef3, {
    freezeOnceVisible: true,
  });
  const dataRef4 = useIntersectionObserver(triggerRef4, {
    freezeOnceVisible: true,
  });
  const dataRef5 = useIntersectionObserver(triggerRef5, {
    freezeOnceVisible: true,
  });

  // 이벤트
  const contentShow1 = useSpring({
    config: { duration: 500 },
    from: { y: 25, opacity: 0 },
    to: {
      y: dataRef1?.isIntersecting ? 0 : 25,
      opacity: dataRef1?.isIntersecting ? 1 : 0,
    },
  });

  const contentShow2 = useSpring({
    config: { duration: 500 },
    from: { y: 25, opacity: 0 },
    to: {
      y: dataRef2?.isIntersecting ? 0 : 25,
      opacity: dataRef2?.isIntersecting ? 1 : 0,
    },
  });

  const contentShow3 = useSpring({
    config: { duration: 500 },
    from: { y: 25, opacity: 0 },
    to: {
      y: dataRef3?.isIntersecting ? 0 : 25,
      opacity: dataRef3?.isIntersecting ? 1 : 0,
    },
  });

  const contentShow4 = useSpring({
    config: { duration: 500 },
    from: { y: 25, opacity: 0 },
    to: {
      y: dataRef4?.isIntersecting ? 0 : 25,
      opacity: dataRef4?.isIntersecting ? 1 : 0,
    },
  });

  const contentShow5 = useSpring({
    config: { duration: 500 },
    from: { y: 25, opacity: 0 },
    to: {
      y: dataRef5?.isIntersecting ? 0 : 25,
      opacity: dataRef5?.isIntersecting ? 1 : 0,
    },
  });
  // 애니메이션 효과 모음 끝!!

  return (
    <>
      {/* 컨텐츠 1번 */}
      <SectionCt ref={firstComp} bg={mainBg} className="type01">
        <Section className="type01">
          <IntroContainer>
            <MainSwiperCt>
              <Swiper
                // autoplay={true}
                autoplay={{ delay: 5000 }}
                className="swiper-container live-swiper"
                spaceBetween={isMobile ? 10 : 1}
                slidesPerView={1}
                loop={false}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{ el: ".swiper-pagination", clickable: true }}
                onSwiper={(swiper) => {
                  Handleanimation();
                }}
                onSlideChange={() => {}}
              >
                {swiperContent.map((item, idx) => {
                  return (
                    <SwiperSlide key={idx} className="swiper-slide live-item">
                      <UrlCt className="textCt">
                        <MainTitle className="desc web">
                          {item.desc01}
                        </MainTitle>
                        <MainTitle className="mainTitle">
                          {item.mainTitle}
                          <br />
                          <strong className="orange">{item.strong} </strong>
                        </MainTitle>

                        <MainTitle className="desc web">
                          {item.desc02} <br />
                          {item.desc03}
                        </MainTitle>
                        <MainBtnCt className="btnCt">
                          {item.btn01 !== "" && (
                            <button
                              onClick={() => {
                                window.open(item.url, "_blank");
                              }}
                              className="site"
                            >
                              {item.btn01}{" "}
                              <i className="ri-external-link-line"></i>
                            </button>
                          )}
                          {item.btn02 !== "" && (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                downloadIntroFile(item.url, item.name);
                              }}
                              className="intro"
                            >
                              {item.btn02} <i className="ri-download-line"></i>
                            </button>
                          )}
                          {item.btn03 && (
                            <button
                              onClick={() => {
                                window.open(item.url2, "_blank");
                              }}
                              className="site"
                            >
                              {item.btn03}{" "}
                              <i className="ri-external-link-line"></i>
                            </button>
                          )}
                          {item.btn04 && (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                downloadIntroFile(item.url2, item.name2);
                              }}
                              className="intro"
                            >
                              {item.btn04} <i className="ri-download-line"></i>
                            </button>
                          )}
                        </MainBtnCt>
                      </UrlCt>
                      <IntroImgCt className="main imgCt">
                        <div
                          className={
                            idx === 2 ? "imgCt01 sizeReduce" : "imgCt01"
                          }
                        >
                          <img alt="mainimg1" src={item.img01}></img>
                        </div>
                        <div
                          ref={idx === 0 ? imgref01 : emptyRef}
                          className="imgCt02"
                        >
                          {item.img02 !== "" && (
                            <img alt="mainimg2" src={item.img02}></img>
                          )}
                        </div>
                        <div
                          ref={idx === 0 ? imgref02 : emptyRef}
                          className="imgCt03 "
                        >
                          {item.img03 !== "" && (
                            <img alt="mainimg3" src={item.img03}></img>
                          )}
                        </div>
                      </IntroImgCt>
                    </SwiperSlide>
                  );
                })}

                {!isMobile && (
                  <SwiperPaginationBtn className="swiper-pagination"></SwiperPaginationBtn>
                )}
              </Swiper>
            </MainSwiperCt>
          </IntroContainer>

          <MoreInfoBtn
            onClick={(e) => {
              scrollContentRef.current.scrollIntoView({ behavior: "smooth" });
            }}
            className="moreInfoBtn"
            aria-label="더보기 버튼"
            icon={iconDownArrow}
          ></MoreInfoBtn>
        </Section>
      </SectionCt>

      {/* tab Content 01 */}
      <div ref={scrollContentRef}></div>
      <div ref={secondComp}></div>
      <animated.div style={{ ...contentShow1 }}>
        <div ref={triggerRef1}>
          <MainContentAdded />
        </div>
      </animated.div>
      {/* tab Content + button 02 */}
      <animated.div
        style={{
          ...contentShow2,
        }}
      >
        <div ref={triggerRef2}>
          <MainContentAdded3 />
        </div>
      </animated.div>

      {/* Img Content */}
      <animated.div
        style={{
          ...contentShow3,
        }}
      >
        <div ref={triggerRef3}>
          <MainContentAdded2 />
        </div>
      </animated.div>

      {/* 컨텐츠 3번 */}
      <animated.div style={{ ...contentShow4 }}>
        <div ref={triggerRef4}>
          <SectionCt className="type03">
            <Section className="type03">
              <Title>
                인공지능(AI) 기술을 기반으로{isMobile && <br />} 비즈니스에
                가치를 더합니다
                <br />
                <span className="orange">
                  메스와이저의 다양한 서비스를 만나보세요
                </span>
              </Title>
              <DataTextRowCt className="type03">
                {pageList.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={(e) => {
                        setInfoState(idx);
                      }}
                      className={infoState === idx ? "infoCt active" : "infoCt"}
                    >
                      <img className="logo" src={item.logo} alt={item.logo} />
                      <p
                        style={{
                          fontSize: "12px",
                          width: "fit-content",
                          // visibility: "visible",
                        }}
                        className="title"
                      >
                        {item.title}
                      </p>
                      <img className="text" src={item.tImg} alt={item.tImg} />
                      <p className="desc">{item.desc[0]}</p>
                      <p className="desc">{item.desc[1]}</p>
                      <p className="desc last">{item.desc[2]}</p>
                      {item.pageActive ? (
                        <NormalButton
                          buttonTitle={
                            <>
                              바로가기 <img src={iconMove} alt="바로가기" />
                            </>
                          }
                          buttonClass={"cOrange small round"}
                          callBackFunc={() => {
                            window.open(item.url, "_blank");
                          }}
                        />
                      ) : (
                        <EmtpySpace />
                      )}
                    </div>
                  );
                })}
              </DataTextRowCt>
            </Section>
          </SectionCt>
        </div>
      </animated.div>

      <animated.div style={{ ...contentShow5 }}>
        <div ref={triggerRef5}>
          <SectionCt>
            <Section>
              <Advice setPopState={setPopState} setPopMessage={setPopMessage} />
            </Section>
          </SectionCt>
          {/* 컨텐츠 문의 및 소개서 받기 */}
          <SectionCt className="type07">
            <Section className="type07">
              <div className="infoCt">
                <p className="text">
                  회원가입 즉시 GPT 기반의 {isMobile && <br />} 메스와이저
                  서비스를
                  <br />
                  <strong>무료로 체험할 수 있습니다!</strong>
                </p>
                {/* <button
              onClick={(e) => {
                e.preventDefault();
                //downloadIntroFile();
                // alert("가야하는 페이지?");
                window.open("https://meswiser.ai", "_blank");
              }}
            >
              회원가입 바로가기 <i className="ri-external-link-line"></i>{" "}
            </button> */}
              </div>
            </Section>
          </SectionCt>
        </div>
      </animated.div>

      {popState && (
        <AlertPop2
          text={popMessage}
          popState={popState}
          setPopState={setPopState}
        />
      )}
      {/* <Route path={`${match.path}Advice`} component={Advice} /> */}
    </>
  );
}

const SectionCt = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  &.type01 {
    padding: 0 30px;
    background: #fefeff url(${({ bg }) => bg}) no-repeat;
    background-size: 100%;
    background-position: bottom;
    @media (min-width: 1024px) {
      background-size: 100%;
      background-position: bottom;
    }
    @media (max-width: 480px) {
      background-size: 100%;
    }
  }
  &.type02 {
    background: #fff;
  }
  &.type03 {
    /* background: url(${({ backImg }) => backImg}) no-repeat;
    background-size: cover; */
    background: #fff;
    overflow: hidden;
  }
  &.type04 {
    background: #fff;
  }
  &.type05 {
    background: #fff;
  }
  &.type06 {
    padding-top: 90px;
    background: #f7f9ff;
  }
  &.type07 {
    background: linear-gradient(0deg, #ffca36 0%, #ffca36 100%), #fff;

    @media (max-width: 480px) {
      padding: 0 20px;
    }
  }
`;

const Section = styled.section`
  max-width: 1200px;
  min-height: 742px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d0d0d;

  @media (max-width: 1000px) {
    flex: 0 1 auto;
    /* margin-top: 100px; */
    /* margin-top: 30px; */
  }

  &.type01 {
    position: relative;
    flex-wrap: wrap;
    /* 높이값 수정 했음 너무 공간이 남아서 */
    /* min-height: calc(100vh - 64px); */
    min-height: calc(90vh - 64px);
    width: 100%;

    @media (max-width: 480px) {
      min-height: calc(80vh - 64px);
    }
  }

  &.type02 {
    flex-wrap: wrap;
    img {
      width: 120px;
      margin: 0 36px;
    }
    .title {
      &.highlight {
        z-index: 0;
        position: relative;
        &::after {
          z-index: -1;
          content: "";
          width: 60%;
          height: 53%;
          position: absolute;
          background: rgba(255, 206, 70, 0.81);
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }

        @media (max-width: 480px) {
          font-size: 24.5px;
          width: 105%;
          &::after {
            width: 100%;
          }
        }

        @media (max-width: 559px) and (min-width: 481px) {
          font-size: 30px;
          letter-spacing: -0.5px;
          &::after {
            width: 100%;
          }
        }
        @media (max-width: 629px) and (min-width: 560px) {
          font-size: 36px;
          letter-spacing: -0.5px;
          &::after {
            width: 100%;
          }
        }
        @media (max-width: 800px) and (min-width: 630px) {
          &::after {
            width: 90%;

            letter-spacing: -0.5px;
          }
        }

        @media (max-width: 1000px) and (min-width: 801px) {
          &::after {
            width: 80%;
            height: 53%;
          }
        }
      }
    }
    /* 추가 콘텐츠 */
    &.Added {
      flex-direction: column;
      & img.contentImg {
        width: 80%;
        margin-bottom: 50px;
      }
    }

    @media (max-width: 480px) {
      img.alvisImg {
        margin: 0 0 30px 0;
      }
      .title {
        color: #1a1a1a;
        font-size: 26px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1px;
        margin-bottom: 50px;
      }
    }
  }
  &.type03 {
    flex-wrap: wrap;
    flex-direction: column;
    min-height: fit-content;
    margin: 80px 0 120px;

    @media (max-width: 1000px) {
      align-items: center;
      justify-content: center;
      .title {
        text-align: center;
        word-break: break-word;
        width: 70%;
      }
    }

    @media (max-width: 480px) {
      margin: 50px 0 70px;
    }

    .title {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-top: 50px;
      margin-bottom: 80px;

      @media (max-width: 480px) {
        font-size: 25px;
        margin-bottom: 50px;
        width: 100%;
        letter-spacing: -0.8px;
      }
      @media (max-width: 375px) {
        font-size: 24px;
        letter-spacing: -1px;
      }
    }

    .infoCt {
      transition: 0.2s;
      display: flex;
      width: 370px;
      height: 310px;
      padding: 30px 20px;
      flex-direction: column;

      border-radius: 45px;
      margin: 0 10px 75px;
      justify-content: flex-start;

      cursor: pointer;
      border: 5px solid transparent;
      background: #f6f7f9;
      &:hover {
        opacity: 0.9;
        box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26);
      }
      &.active {
        background: #fff;
        border: 5px solid #ff892a;
        box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26);

        & button {
          color: #000;
          background: transparent;
          /* border: 1px solid #ff892a; */
          border-radius: 50px;
        }
      }
      .title {
        color: #1a1a1a;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1px;
        word-break: keep-all;
        margin: 18px 0 1px;

        min-height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0;
        width: 0;
        height: 0;
        position: absolute;
        visibility: hidden;
      }
      .desc {
        color: #1a1a1a;
        font-size: 19px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1px;
        position: relative;

        text-align: center;
      }
      & button {
        width: 50%;
        margin: 10px auto 0;
        margin-top: auto;
        padding: 3.5px 15px;
        background: transparent;
        color: #444444;

        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 480px) {
          margin-top: auto;
        }
      }

      @media (max-width: 1000px) {
        margin-bottom: 30px;
        padding: 10px 20px;
        width: 300px;
        height: 320px;
        justify-content: flex-start;
        & .title {
          margin-top: 10px;
          width: 100%;
          margin: 10px 0;
          line-height: 30px;
        }
      }

      @media (max-width: 480px) {
        height: 200px;
        min-height: 200px;
        flex: 0 1 45%;
        padding: 25px 5px 5px;
        margin: 5px 7px;
        justify-content: flex-start;

        .title {
          color: #1a1a1a;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -1px;
          margin: 18px 0 0;
          min-height: 35px;
          align-self: start;
        }
        .desc {
          font-size: 12px;
          word-break: keep-all;
          /* margin-left: 10px; */
          &:after {
            top: 10px;
          }
        }
      }
    }
  }
  &.type04 {
    min-height: 900px;
    //margin-top: 100px;
    margin: 100px 0;
    flex-wrap: wrap;
    @media (max-width: 1000px) {
      height: auto;
    }
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }
  &.type05 {
    height: 600px;
    min-height: unset;
    padding-top: 60px;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: 480px) {
      padding-top: 10px;
      margin: 80px 0;
    }

    & p.line {
      width: 533px;
      height: 1px;
      background: #000;
      margin-bottom: 38px;
    }
    & p.title {
      color: #1a1a1a;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 18px;
    }
    & p.desc {
      color: #969595;
      text-align: center;
      font-size: 30px;
      font-weight: 350;
      line-height: normal;
      letter-spacing: 0.75px;
      margin-bottom: 120px;
    }

    @media (max-width: 1000px) {
      height: auto;
      & p.line {
        width: 80%;
      }

      & p.title,
      & p.desc {
        word-break: keep-all;
      }
      & p.title {
        font-size: 36px;
        width: 70%;
      }

      & p.desc {
        font-size: 24px;
        width: 55%;
      }
    }
    @media (max-width: 480px) {
      & p.title {
        width: 100%;
        color: #232323;
        font-size: 28px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
      }
      & p.desc {
        width: 100%;
        font-size: 20px;
        font-weight: 350;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-bottom: 40px;
      }
    }
  }
  &.type06 {
    flex-direction: column;
    margin-bottom: 125px;
    flex-wrap: wrap;

    @media (max-width: 1000px) {
      margin-top: 0;
      min-height: unset;
    }

    .title {
      word-break: keep-all;
      color: #1a1a1a;
      text-align: center;
      font-size: 40px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 41px;
      .strong {
        font-weight: bold;
      }
      @media (max-width: 1000px) {
        width: 85vw;
        font-size: 34px;
      }
      @media (max-width: 480px) {
        font-size: 26px;
        letter-spacing: -2px;
        .strong {
        }
      }
    }
    .tabBtnCt {
      margin-bottom: 61px;
      /* width: 539px; */
      border-radius: 87px;
      background: #e7ebf9;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1000px) {
        font-size: 32px;
        /* width: 40%; */
      }
      @media (max-width: 480px) {
        width: 335px;
        height: 79px;
        margin-bottom: 40px;
      }
      button {
        transition: 0.2s;
        font-size: 30px;

        line-height: normal;
        letter-spacing: -0.34px;
        margin: 9px 13px 10px;

        color: rgba(0, 0, 0, 0.4);
        padding: 8px 57px;
        border-radius: 54px;
        @media (max-width: 1000px) {
          padding: 8px 20px;
        }
        @media (max-width: 480px) {
          font-size: 26px;
          flex: 0 0 auto;
          margin: 9px;
          width: 149px;
          height: 61px;
        }
      }
      button.active {
        padding: 8px 57px;
        color: #1a1a1a;
        border-radius: 54px;
        background: #fff;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        font-weight: 700;
        @media (max-width: 1000px) {
          padding: 8px 20px;
        }
        @media (max-width: 480px) {
        }
      }
    }
    .tabContent {
      display: flex;
      .contentCt {
        transition: 0.2s;
        width: 463px;
        /* height: 530px; */
        margin: 0 15.5px;

        position: relative;

        border-radius: 20px;
        background: #e7ebf9;

        .header {
          border-bottom: 1px solid #fff;
        }

        &.active {
          border-radius: 20px;
          border: 1px solid #6d96ff;
          background: #fff;
          box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26);
          .header {
            border-bottom: 1px solid #6d96ff;
            position: relative;
            &::after {
              position: absolute;
              content: "";
              width: 24px;
              height: 24px;
              top: 50%;
              left: 32%;
              transform: translate(0, -50%);
              border-radius: 100%;
              background: url(${iconCheckWhite}) no-repeat;
              background-position: center;
              background-size: 12px;
              background-color: #ff892a;
            }
          }
        }

        .header {
          height: 80px;
          color: #1a1a1a;
          font-size: 25px;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .unit {
          margin: 20px 0px 0px 40px;
          color: #1a1a1a;
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -1px;
          height: 20px;
          display: flex;
          align-items: center;
        }
        .desc.hasSpan {
          flex-direction: column;
          align-items: start;
          @media (max-width: 480px) {
            position: relative;
          }
        }
        .desc {
          margin: 6px 45px 6px 70px;
          color: #1a1a1a;
          font-size: 18px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -1px;
          height: 40px;
          display: flex;
          align-items: center;

          position: relative;

          &.letterSpacing {
            letter-spacing: -0.5px;
          }

          @media (max-width: 480px) {
            margin-left: 45px;
            margin-right: 15px;
          }

          & span {
            font-size: 13px;
          }
          &::after {
            position: absolute;
            background: url(${iconCheckOrange}) no-repeat;
            background-position: center;
            background-size: contain;
            content: "";
            width: 24px;
            height: 24px;
            top: 50%;
            left: -44px;
            transform: translate(50%, -50%);
          }

          &.lineTwo {
            line-height: inherit;
          }

          &.last {
            margin-bottom: 35px;
          }
        }
        .descSpecial {
          position: absolute;
          bottom: 15px;
          font-size: 16px;
          color: #ff893a;
          width: 100%;
          text-align: center;
        }

        @media (max-width: 1000px) {
          display: none;
          &.active {
            display: block;
          }
        }
      }
      @media (max-width: 480px) {
        .contentCt {
          width: 335px;

          &.free {
            /* height: 260px; */
            & p.desc.last {
              margin-bottom: 35px;
            }
            & .descSpecial {
              bottom: 10px;
              font-size: 14px;
            }
          }
        }

        .header {
          font-size: 24px !important;
          padding-left: 32px;
        }
        .desc {
          font-size: 15px !important;
          word-break: keep-all;
          letter-spacing: -1px !important;
        }
      }
    }
  }
  &.type07 {
    min-height: auto;
    text-align: center;
    background: #ffca36;
    display: flex;

    @media (max-width: 1000px) {
      width: 100%;
      height: auto;
      min-height: 512px;
      margin-top: 0;
      flex-direction: column;
      justify-content: space-around;
    }

    @media (max-width: 480px) {
      justify-content: space-around;
      min-height: auto;
      /* min-height: 330px; */
    }

    @media (max-width: 1100px) and (min-width: 1000px) {
      flex-wrap: wrap;
    }

    & .infoCt {
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      justify-content: center;

      margin-bottom: 0 !important;

      height: 150px;
      width: 540px;

      @media (max-width: 1090px) {
        &.first {
          padding-right: 0 !important;
        }
      }
      @media (min-width: 481px) and (max-width: 1000px) {
        &.first {
          padding-right: 20px !important;
        }
      }

      @media (max-width: 1080px) and (min-width: 1000px) {
        &.first {
          border-bottom: 1px solid #000;
          border-right: none !important;
          padding-bottom: 25px;
          margin-bottom: 30px;
          position: relative;
        }

        &.last {
          padding-left: 0px !important;
          margin-bottom: 30px;
        }
      }
      @media (max-width: 1000px) {
        width: 100%;
        height: 200px;
        flex-direction: column;
        padding: 0 20px;
        & .text {
          margin-bottom: 32px;
          margin-left: 20px;
          width: 100%;
        }
        & button {
          align-self: end;
        }
      }

      @media (max-width: 480px) {
        padding: 0;
      }

      & .text {
        color: #1a1a1a;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1px;
        text-align: left;
        margin-right: 10px;

        text-align: center;
        strong {
          font-weight: bold;
        }

        @media (max-width: 480px) {
          font-size: 22px;
        }
      }
      &.first {
        border-right: 1px solid #000;
        padding-right: 25px;
        margin-bottom: 30px;
        position: relative;

        @media (max-width: 1000px) {
          border-right: none;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: #000;
            bottom: -30px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }
      &.last {
        padding-left: 25px;
        margin-bottom: 30px;
      }
      &.first,
      &.last {
        @media (max-width: 1000px) {
          justify-content: center;
        }

        @media (max-width: 480px) {
          padding: 0;

          button {
            width: 220px;
            height: 60px;
            font-size: 22px;
          }
        }
      }
      button {
        transition: 0.2s;
        color: #1a1a1a;
        text-align: center;
        font-size: 25px;
        font-weight: 500;
        line-height: normal;
        border-radius: 52px;
        background: #fff;
        letter-spacing: -0.22px;
        justify-content: center;
        align-items: center;
        padding: 10px 30px;
        flex-shrink: 0;
        margin: 0 auto;
        &:hover {
          background: #fda055;
          color: #ffffff;
        }
      }

      flex-direction: column;
    }
  }
`;

const DataTextRowCt = styled.div`
  margin-bottom: 82px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media (max-width: 480px) {
    &.type02.mobile {
      margin-bottom: 0;
    }
  }

  &.alvisFlow {
    justify-content: flex-start;
  }

  &.type03 {
    flex-wrap: wrap;
    margin-bottom: 0;

    & .infoCt {
      background-color: #e7f0fb;
      & p.desc {
        word-break: keep-all;
      }
    }
    & img {
      &.logo,
      &.text {
        margin: 0 auto;
      }
      &.logo {
        width: 100%;
        /* height: 55px; */
      }
      &.text {
        width: 100%;
        /* height: 30px; */
        margin-bottom: 14px;
      }

      @media (max-width: 480px) {
        &.logo {
          width: 100%;
        }
        &.text {
          width: 100%;
        }
      }
    }
  }

  &.type04 {
    margin-bottom: 0;

    @media (max-width: 1100px) and (min-width: 1000px) {
      flex-wrap: wrap;
    }
  }

  &.type05 {
    width: 1000px;
    text-align: center;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 480px) {
      width: unset;
      margin-bottom: 0;
      padding: 0;

      & img.part {
        margin: 10px;
        height: 25px;
      }
    }
    & img.part {
      margin: 15px;
    }
  }
  & img.part {
    margin-right: 94px;
    height: 35px;
  }

  &.mobile {
    flex-direction: column;
    align-items: center;
  }
`;

const EmtpySpace = styled.div`
  height: 50px;
`;

const BotAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const MoreInfoBtn = styled.button`
  width: 50px !important;
  height: 35px !important;
  padding: 0 !important;

  position: absolute;
  bottom: 0px;
  left: 48%;
  transform: translateX(-50%);

  background: url(${({ icon }) => icon}) no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;

  animation: ${BotAnimation} 1s infinite linear;

  @media (max-width: 480px) {
    /* bottom: -30px; */
    /* left: 45%; */
    left: unset;
    min-width: 50px !important;
  }
`;

const Title = styled.p`
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  margin-bottom: 79px;
  word-break: keep-all;

  @media (max-width: 480px) {
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1px;
    & span.orange {
      font-size: 20px;
    }
  }

  &.hasImg {
    & img {
      width: 240px;
    }
    margin: 30px 0px;
    @media (max-width: 480px) {
      margin-bottom: 0;
    }
  }

  &.type02 {
    letter-spacing: -0.4px;
    font-size: 30px;
    line-height: 45px;

    &.Added {
      /* margin-top: 50px; */
      color: #333;
      text-align: center;
      font-size: 26px;
      font-weight: 300;
      .strong {
        font-weight: 700;
      }

      &.orange {
        color: #ff892a;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
      }

      &.Desc {
        color: #333;
        text-align: center;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        & span {
          font-weight: 700;
        }
        @media (max-width: 480px) {
          font-size: 18px !important;
          line-height: 24px;
        }
      }
    }

    @media (max-width: 480px) {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.28px;

      .strong {
        font-size: 40px;
      }
      .desc {
        display: inline-block;
        margin-top: 8px;
        font-size: 26px;
        font-weight: 400;
        letter-spacing: -1.5px;
      }
    }
  }
  &.type04 {
    @media (max-width: 480px) {
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
      margin-bottom: 30px;
    }
  }
  & span.orange {
    color: #ff892a;
  }
`;

const IntroContainer = styled.div`
  display: flex;
  /* margin-top: 10vh; */

  width: 100%;
  min-height: 30%;

  @media (min-width: 1001px) {
    margin-top: -100px;
  }

  @media (max-width: 1000px) {
    justify-content: start;
  }
  @media (max-width: 480px) {
    margin-top: 10vw;
    flex-wrap: wrap;
  }
`;
const move1 = keyframes`
  0% {
    top: -5%;
    left: 0;
    opacity: 0;
  }
  100% {
    top: 0%;
    left: 0;
    opacity: 1;
  }
  
`;

const move2 = keyframes`
  0% {
    top: 39%;
    opacity: 0;
    scale: 1;
  }
  50%{
    top: 44%;
    scale: 1.2;
  }
  100% {
    top: 39%;
    left: 13%;
    opacity: 1;
    scale: 1;
  }
`;

const move3 = keyframes`
  0% {
    top: 25%;
    left: 38%;
    opacity: 0;
    scale: 1;
  }
  50%{
    top: 30%;
    scale: 1.1;
  }
  100% {
    top: 25%;
    left: 38%;
    opacity: 1;
    scale: 1;
  }
`;

const IntroImgCt = styled.div`
  flex: 0 0 45%;
  position: relative;
  margin-left: 1px;

  /* @media (min-width: 480px) {
    transform: translateY(-15%);
  } */

  & div {
    height: auto;
    position: relative;
    flex: 0 1 100%;

    &.imgCt01 {
      width: 100%;
      /* position: absolute; */
      animation: ${move1} 0.4s ease-in-out;
      &.sizeReduce {
        text-align: center;
        padding-top: 30px;

        width: 90%;
      }
      & img {
        width: inherit;
      }
    }
    &.imgCt02 {
      display: none;
      position: absolute;
      width: 26%;
      &.active {
        display: block;
        animation: ${move2} 0.4s ease-in-out;
        top: 39%;
        left: 13%;
      }
      & img {
        width: 100%;
      }
    }
    &.imgCt03 {
      display: none;
      position: absolute;
      width: 62%;
      &.active {
        animation: ${move3} 0.4s ease-in-out;
        top: 25%;
        left: 38%;
        display: block;
      }
      & img {
        width: 100%;
      }
    }
  }

  @media (max-width: 1000px) {
    &.main {
      /* height: 300px; */
      flex: 0 0 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(0);
    }
    /* & .imgCt01 {
      width: 65% !important;
      text-align: center;
      top: 0;
    }
    & .imgCt02 {
      width: 15% !important;
      top: 42% !important;
      left: 40% !important;
    }
    & .imgCt03 {
      width: 35% !important;
    } */
  }
`;

const MainTitle = styled.p`
  /* margin-left: 10px; */
  color: #000;
  letter-spacing: -1px;
  text-align: left;

  font-size: 40px;
  font-weight: 700;
  line-height: 55px;

  @media (max-width: 1024px) {
    font-size: 30px;
    line-height: normal;
  }

  @media (max-width: 1024px) {
    /* order: 2; */
  }

  @media (max-width: 480px) {
    margin-left: 0;
    color: #1a1a1a;
    font-size: 16px;
    line-height: 162.3%;
    letter-spacing: -1px;
    min-width: 100%;
    &.mainTitle {
      font-size: 22px;
    }
  }

  & strong {
    font-weight: bold;
    &.orange {
      color: #ff892a;
    }
  }

  &.desc {
    font-size: 16px;
    font-weight: 400;
    word-break: keep-all;
    line-height: normal;
    &.web {
      width: 100%;
      letter-spacing: -0.5px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  &.creating {
    font-size: 25px;
    text-align: center;
    line-height: normal;

    &.mobile {
      display: none;
    }

    @media (max-width: 480px) {
      display: none;

      &.mobile {
        display: block;
        font-size: 18px;
      }
    }
  }
`;

const UrlCt = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 50px;
  /* margin-left: 85px; */
  /* margin-top: 50px; */
  /* margin-top: 100px; */
  margin-bottom: 50px;

  animation: ${move1} 0.3s ease-in-out;

  p {
    &.desc.web {
      margin-bottom: 11px;
    }
    &.mainTitle {
      margin-bottom: 22px;
    }
  }

  &.inputBox {
    @media (min-width: 481px) {
      width: 100%;
      padding: 0 10%;
    }
    @media (max-width: 480px) {
      padding: 0 10%;
    }
  }

  @media (max-width: 1000px) {
    flex: 0 1 30%;
    margin: 0 25px;
    margin-left: 0;

    & p {
      margin-left: 0;
      text-align: center;
      &.desc.web {
        &:first-child {
          margin: 0;
        }
        /* margin: 0; */
        font-size: 13px;
      }
      &.mainTitle {
        margin: 0;
        font-size: 22px;
      }
    }
  }
  @media (max-width: 480px) {
    /* flex: 0 0 100%; */
    margin: 15px auto 50px;
    order: 2;
    p {
      text-align: center;
    }
  }
`;

const MainSwiperCt = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    margin: 0 auto;
  }

  & .swiper-container {
    width: 100%;
    z-index: 0;
  }

  & .swiper-slide {
    display: flex;
    height: unset;
    .textCt {
      /* width: 45%; */
    }
    .main.imgCt {
      flex: 1 0 auto;
      width: 50%;
      z-inedx: 1;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      .main.imgCt,
      .textCt {
        width: 100%;
      }
      .main.imgCt {
        flex: 1 0 45%;
      }
      .textCt {
        /* margin: ; */
      }
    }
  }
  /* overflow: hidden; */
  & img.main {
    /* width: 300px;
    height: 300px;

    margin-bottom: 20px;
    @media (max-width: 1000px) {
      width: 200px;
      height: 200px;
    } */
  }
`;

const SwiperPaginationBtn = styled.div`
  &.swiper-pagination {
    text-align: center !important;
    bottom: 0;
    & .swiper-pagination-bullet-active {
      color: #3289fc;
    }
    & .swiper-pagination-bullet {
      background: rgba(50, 137, 252, 0.4);
      width: 5px;
      height: 5px;
      margin: 0 3px;
      bottom: 10px;
    }
  }
`;

const MainBtnCt = styled.div`
  display: flex;
  flex-wrap: wrap;

  & button {
    flex: 0 0 44%;
    /* min-width: 105px !important; */
    color: #fff;
    font-weight: 500;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 30px;
    word-break: keep-all;
    margin: 2.5px;
    letter-spacing: -1px;
  }
  & button.site {
    border: 2px solid #ff892a;
    color: #333;

    &:hover {
      border: 2px solid #ffca36;
      background: #ffca36;
      color: #fff;
    }
  }
  & button.intro {
    background: #ff892a;

    &:hover {
      background: #ffca36;
    }
  }

  @media (max-width: 480px) {
    justify-content: center;
    order: 3;
    margin-top: 10px;
    font-weight: bold;
    & button {
      font-size: 12px;
      flex: 0 0 35%;
      min-width: fit-content !important;
    }
  }
`;
