import MainContent from "components/organisms/MainContent";
import styled from "styled-components";
import SideNavButton from "../../organisms/SideNavButton";
// import cookie from "react-cookies";
// import { useEffect, useState } from "react";
// import banner1 from "assets/images/banner/AIVoucher_banner.png";

export default function HomeMain({ firstComp, secondComp }) {
  return (
    <Wrapper>
      <Content className="content">
        <MainContent firstComp={firstComp} secondComp={secondComp} />
        <SideNavButton />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  overflow: hidden;
`;
