import moment from "moment/moment.js";

/**
 * @return "HH:mm"
 * */
export const getNow = () => {
  return String(moment()._d).slice(15, 21);
};

export const timestampToTime = (timestamp) => {
  timestamp = timestamp?.trim() ?? "";

  if (timestamp.length === 0) return getNow();
  if (timestamp.length >= 3 && timestamp.length <= 5) {
    return timestamp;
  }
  const momentObj = moment(timestamp, "YYYY-MM-DD HH:mm:ss.S");
  return momentObj.format("HH:mm");
};
