import BrowserEnvContextProvider from "./providers/BrowserEnvContextProvider.js";

export default function AppContextProvider({
  isMobile,
  children: AppComponent,
}) {
  return (
    <BrowserEnvContextProvider isMobile={isMobile}>
      {AppComponent}
    </BrowserEnvContextProvider>
  );
}
