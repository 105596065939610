import NormalButton from "components/atomic/NormalButton";
import { Fragment } from "react";
import styled from "styled-components";

export default function ConfirmPop({
  setShowConfirmPop,
  callback,
  cancelCallback,
  title,
  description,
  btnText,
  popClass,
}) {
  const Cancel = () => {
    setShowConfirmPop(false);
    cancelCallback && cancelCallback();
  };

  const CallBack = () => {
    setShowConfirmPop(false);
    callback && callback();
  };

  return (
    <ConfirmModal>
      <Wrpper className={popClass}>
        <Header>
          <HTitle>
            {title.split("\\n").map((txt, index) => (
              <Fragment key={index}>
                {txt}
                <br />
              </Fragment>
            ))}
          </HTitle>
        </Header>
        <Body>
          {description && <span className="txt">{description}</span>}
          <ButtonGroup>
            <NormalButton
              disabled={false}
              buttonId="cancel"
              buttonTitle="취소"
              buttonClass="cDel"
              callBackFunc={Cancel}
            />
            <NormalButton
              disabled={false}
              buttonTitle={btnText ? btnText : "확인"}
              buttonClass="cOrange"
              callBackFunc={CallBack}
            />
          </ButtonGroup>
        </Body>
      </Wrpper>
    </ConfirmModal>
  );
}

const ConfirmModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Wrpper = styled.div`
  width: 280px;
  text-align: center;
  padding-top: 20px;

  position: absolute;

  top: 40%;
  left: 50%;
  transform: translateX(-50%);

  &.dash {
    top: 30%;
    left: 40%;
  }

  @media (max-width: 480px) {
    left: 50%;
  }

  border: 1px solid #dbdbdb;
  /* padding: 0 20px; */
  background: #fff;
  z-index: 99;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.2);

  min-width: 82px;
  right: 10px;

  &.own {
    left: 50%;
  }

  max-height: 250px;
`;

const Header = styled.strong`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const HTitle = styled.div`
  width: 100%;
  line-height: normal;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 0;
  padding-top: 30px;
  word-break: keep-all;
  /* overflow-y: auto; */
  /* word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis; */
`;

const Body = styled.div`
  background: #fff;
  border: 1px solid transparent;

  & span.txt {
    padding-top: 10px;
    padding: 17px 0;
    font-size: 13px;
    letter-spacing: -0.5px;
    word-break: keep-all;
  }
`;

const ButtonGroup = styled.div`
  margin-top: 25px;
  display: flex;

  button {
    width: 50% !important;
    height: 45px !important;
    display: block;
    font-size: 13px !important;
    font-weight: 500;
    margin: 0;
    padding: 0;

    border: none !important;
    border-top: 1px solid #ff892a !important;
    border-radius: 0;
    cursor: pointer;

    &.cDel {
      color: #ff892a !important;
    }
  }
`;
