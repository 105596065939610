import { css } from "@emotion/react";
import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import Loader from "react-spinners/PuffLoader";

const puffStyle = css`
  display: block;
  margin: auto;
`;

export default function LoadingComponent() {
  return createPortal(
    <div className="loading_container">
      <Loader css={puffStyle} size={60} loading={true} />
    </div>,
    document.getElementById("modal")
  );
}
