import React from "react";
import "./pp.css";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
import "remixicon/fonts/remixicon.css";

export default function PersonalPolicy({ setPersonalData }) {
  const handleCloseModal = () => {
    setPersonalData((prev) => !prev);
  };

  return (
    <>
      {createPortal(
        <Wrapper>
          <Container>
            <Header>
              개인정보처리방침
              <button
                className="ri-close-line"
                onClick={handleCloseModal}
              ></button>
            </Header>
            <TextContents>
              <div className="policy-container__textBox">
                <h2 className="policy__main__title">
                  개인정보 수집 및 이용 동의(필수)
                </h2>
                <p className="policy__desc">
                  귀하는 아래 개인정보 수집에 대한 동의를 거부할 권리가 있으며,
                  동의 거부 시에는 회원가입이 제한될 수 있습니다. 더 자세한
                  내용에 대해서는 [개인정보처리방침]을 참고하시길 바랍니다.
                </p>
              </div>
              <table className="policy__table mBott">
                <tbody>
                  <tr className="table__firstRow">
                    <td>
                      <h2 className="table__header">1.수집 및 이용목적</h2>
                    </td>
                    <td>
                      <h2 className="table__header">
                        2.수집하려는 개인정보 항목
                      </h2>
                    </td>
                    <td>
                      <h2 className="table__header">3.보유 및 이용기간</h2>
                    </td>
                  </tr>
                  <tr className="table__secRow">
                    <td>
                      <p>회원가입 및 서비스 운영</p>
                    </td>
                    <td>
                      <p>
                        필수 : 회원계정(또는 아이디), 비밀번호, 이메일주소,
                        서비스 이용 기록, 서비스 방문 기록
                      </p>
                    </td>
                    <td>
                      <p>동의 철회 또는 회원탈퇴 후 3년까지</p>
                    </td>
                  </tr>
                  <tr className="table__thrRow">
                    <td>
                      <p>SNS 계정연동</p> <p>&#40;Kakao, Naver, Google&#41;</p>
                    </td>
                    <td>
                      <p>필수 : 계정식별 정보</p>
                    </td>
                    <td>
                      <p>동의 철회 또는 회원탈퇴 후 3년까지</p>
                    </td>
                  </tr>
                  <tr className="table__thrRow">
                    <td>
                      <p>재화 또는 서비스 제공</p>
                    </td>
                    <td>
                      <p>필수 : 회원계정(또는 아이디)</p>
                    </td>
                    <td>
                      <p>동의 철회 또는 회원탈퇴 후 3년까지</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="policy-container__textBox">
                <h2 className="policy__main__title">개인정보 처리방침</h2>
                <h2 className="policy__title">
                  챗봇 생성 및 운영&nbsp; &ldquo;메스와이저(MESwiser)&rdquo;
                  개인 정보처리 방침
                </h2>
                <p className="policy__desc mBott">
                  주식회사 리스트(이하 &ldquo;회사&rdquo;라고합니다.)가 운영하는
                  웹사이트 수집 및 문서 수집 기반 챗봇 생성 및 운영 서비스
                  &quot;메스와이저&quot;는 개인정보보호법에 따라 정보주체(이하
                  &ldquo;회원&rdquo;이라고 합니다.)의 개인정보를 보호하고, 이와
                  관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여
                  다음과 같이 개인정보 처리방침을 수립합니다. 본 개인정보
                  처리방침은 회사가 운영하는 메스와이저 서비스에 적용되며,
                  회원에게 수집하거나 회원이 당사에 제공하는 개인정보에 대해
                  회사의 개인정보 처리방식을 설명합니다. 개인정보 처리방침에서
                  사용되는 용어는 관련 법령 및 당사의 이용약관에서 정한 바에
                  따르며, 그 밖의 사항은 일반적인 상관례에 따릅니다.
                </p>
                <h2 className="policy__title">제 1 조 개인정보의 처리 목적</h2>
                <p className="policy__desc no">
                  1. 회사는 다음 목적을 위해 개인 정보를 수집·이용합니다.
                  <span className="policy__desc__detail">
                    ① 메스와이저 서비스 회원가입, SNS 계정 연동 및 서비스 운영
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 회원가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별 및
                    인증, 회원자격 유지 및 관리, 서비스 부정 이용 방지, 각종
                    고지 및 통지, 고충처리
                  </span>
                  <span className="policy__desc__detail">
                    ② 마케팅 및 서비스 개선
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 이벤트 안내, 정보 안내, 참여통계, 이용 데이터 분석 및
                    서비스 고도화
                  </span>
                </p>
                <p className="policy__desc no">
                  2. 수집·이용하고 있는 개인 정보는 정해진 목적 이외의 용도로
                  이용하지 않으며, 이용 목적이 변경되는 경우 개인정보보호법
                  제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행 할
                  예정입니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 2 조 개인정보의 처리 및 보유 기간
                </h2>
                <p className="policy__desc no">
                  1. 회사는 메스와이저 서비스 이용에 필요한 개인정보를 다음과
                  같은 목적을 위하여 처리하며, 동의 받은 개인정보만 수집 및
                  이용합니다. 처리하는 개인정보는 다음의 목적 이외의 용도로는
                  처리되지 않으며, 처리 목적과 변경되는 경우에는 필요한 조치를
                  이행할 것입니다.
                </p>
                <table className="policy__table">
                  <tbody>
                    <tr className="table__firstRow">
                      <td>
                        <h2 className="table__header">처리 목적</h2>
                      </td>
                      <td>
                        <h2 className="table__header">처리 항목</h2>
                      </td>
                      <td>
                        <h2 className="table__header">처리 및 보유기간</h2>
                      </td>
                    </tr>
                    <tr className="table__secRow">
                      <td>
                        <p>회원가입 및 서비스 운영</p>
                      </td>
                      <td>
                        <p>
                          필수 : 회원계정(또는 아이디), 비밀번호, 이메일주소,
                          서비스 이용기록, 서비스 방문기록
                        </p>
                      </td>
                      <td>
                        <p>동의 철회 또는 회원탈퇴 후 3년까지</p>
                      </td>
                    </tr>
                    <tr className="table__thrRow">
                      <td>
                        <p>SNS 계정연동</p>
                        <p>&#40;Kakao, Naver, Google&#41;</p>
                      </td>
                      <td>
                        <p>필수 : 계정식별정보</p>
                      </td>
                      <td>
                        <p>동의 철회 또는 회원탈퇴 후 3년까지</p>
                      </td>
                    </tr>
                    <tr className="table__thrRow">
                      <td>
                        <p>재화 또는 서비스 제공</p>
                      </td>
                      <td>
                        <p>필수 : 회원계정(또는 아이디)</p>
                      </td>
                      <td>
                        <p>동의 철회 또는 회원탈퇴 후 3년까지</p>
                      </td>
                    </tr>
                    <tr className="table__thrRow">
                      <td>
                        <p>마케팅 및 서비스 개선</p>
                      </td>
                      <td>
                        <p>
                          선택 : 회신계정(또는 아이디), 비밀번호, 이메일주소,
                          서비스 이용기록 및 통계
                        </p>
                      </td>
                      <td>
                        <p>동의 철회 또는 회원탈퇴 후 3년까지</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="policy__desc no">
                  2. 서비스 이용과정이나 개인정보 처리 과정에서 다음과 같은
                  정보들이 자동생성/수집되고 다음의 목적으로 이용될 수 있습니다.
                  <span className="policy__desc__detail">
                    ① IP주소, 단말기 OS정보, 서비스 이용기록, 방문기록
                  </span>
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 3 조 개인정보의 제3자 제공 및 개인정보처리의 위탁
                </h2>
                <p className="policy__desc no">
                  1. 회사는 고객의 개인정보를 서비스 이용약관, 개인정보
                  처리방침의 개인정보의 처리 목적에서 알린 범위 내에서 사용하며,
                  이 범위를 초과하여 이용하거나 타인 또는 다른 기업 또는 기관에
                  제공하지 않습니다. 단, 메스와이저 서비스 이용과정에서 회원의
                  동의가 있거나 법령에 따라 정해진 절차에 따라 정보를 요청받은
                  경우는 예외로 하며, 이 경우 주의를 기울여 개인정보를 이용 및
                  제공할 수 있습니다.
                </p>
                <p className="policy__desc no">
                  2. 회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부
                  업체에 위탁할 수 있으며, 위탁 받은 업체가 개인정보보호법에
                  따라 개인정보를 안전하게 처리하도록 필요한 사항을 규정 하고
                  관리 및 감독에 대한 책임이 있습니다. * 수탁업체 현황 : 없음
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 4 조 개인정보의 파기절차 및 파기방법
                </h2>
                <p className="policy__desc no">
                  1. 회사는 개인정보 보유기간의 경과, 처리목적 달성 등
                  개인정보가 불필요하게 되었을 때에는 바로 해당 개인정보를
                  파기합니다.
                </p>
                <p className="policy__desc no">
                  2. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                  <span className="policy__desc__detail">
                    ① 파기절차 : 회사는 개인정보처리명은 파기 사유가 발생한
                    개인정보를 선정하고, 개인정보 처리자명의 개인정보
                    보호책임자의 승인을 받아 개인정보를 파기합니다.
                  </span>
                  <span className="policy__desc__detail">
                    ② 파기방법 : 회사는 전자적 파일 형태로 기록·저장된
                    개인정보는 기록을 재생할 수 없도록 파기하며, 전자적 파일의
                    형태 외의 기록물, 인쇄물, 서면 그 밖의 기록 매체인 경우의
                    개인정보는 파쇄하거나 소각하여 파기합니다.
                  </span>
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 5 조 만14세 미만 아동의 개인정보 처리에 관한 사항
                </h2>
                <p className="policy__desc">
                  회사는 법정대리인의 동의가 필요한 만14세 미만 아동에 대한
                  정보를 수집 및 이용하지 않습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 6 조 회원의 권리·의무 및 그 행사방법에 관한 사항
                </h2>
                <p className="policy__desc no">
                  1. 회원은 회사에 대해 언제든지 개인정보
                  열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                  <span className="policy__desc__detail">① 고객센터</span>
                  <span className="policy__desc__detail hyphen">
                    – My Page 내 문의하기
                  </span>
                  <span className="policy__desc__detail">② 전자우편</span>
                  <span className="policy__desc__detail hyphen">
                    – 이메일 : help@meswiser.ai
                  </span>
                </p>
                <p className="policy__desc no">
                  2. 제1항에 따른 권리 행사는 회원에 대해 개인정보보호법 시행령
                  제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을
                  통하여서 하실 수 있으며, 회사는 이에 대해 바로 조치하겠습니다.
                  <span className="policy__desc__detail">① 회원탈퇴</span>
                  <span className="policy__desc__detail hyphen">
                    - 메스와이저 서비스 내 회원탈퇴 메뉴
                  </span>
                  <span className="policy__desc__detail">
                    ② 고객센터 및 전자우편
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - My Page 내 문의하기
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 이메일 : help@meswiser.ai
                  </span>
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 7 조 개인정보 보호책임자의 성명 또는 개인정보 보호업무 및
                  관련 고충사항을 처리하는 부서의 명칭과 전화번호 등 연락처
                </h2>
                <p className="policy__desc no">
                  1. 회사는 회원이 안전하게 서비스를 이용할 수 있도록
                  개인정보보호에 최선을 다하고 있습니다. 개인정보를 보호하고
                  개인정보와 관련된 사항을 처리하기 위해 아래와 같이
                  개인정보보호 책임자를 지정하고 있습니다.
                </p>
                <p className="policy__desc no">
                  2. 회원은 서비스를 이용하면서 발생한 모든 개인정보보호 관련
                  문의, 불만처리, 피해 구제 등에 관한 사항을 개인 정보관리
                  책임자 및 담당부서로 문의하실 수 있습니다.
                  <span className="policy__desc__detail">① 성명 : 최두</span>
                  <span className="policy__desc__detail">
                    ② 부서명 : 기술연구소
                  </span>
                  <span className="policy__desc__detail">
                    ③ 이메일주소 : help@meswiser.ai
                  </span>
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 8 조 개인정보의 안전성 확보 조치에 관한 사항
                </h2>
                <p className="policy__desc">
                  회사는 「개인정보보호법」 제29조에 따라 다음과 같이 안전성
                  확보에 필요한 기술력, 관리적, 물리적 조치를 하고 있습니다.
                  <span className="policy__desc__detail">
                    ① 중요 개인정보의 암호화
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 회원의 비밀번호는 일방향 암호화하여 저장 및 관리되고
                    있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에
                    의해서만 가능합니다.
                  </span>
                  <span className="policy__desc__detail">
                    ② 해킹 등에 대비한 대책
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 회사는 해킹, 컴퓨터 바이러스 등 정보통신망 침입에 의해
                    회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을
                    다하고 있습니다.
                  </span>
                  <span className="policy__desc__detail">
                    ③ 개인정보 처리 최소화 교육
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 회사는 개인정보 관련 처리 담당자를 최소한으로 제한하여,
                    개인정보 처리자에 대한 교육 등 관리적 조치를 통해 법령 및
                    내부방침 등의 준수를 강조하고 있습니다.
                  </span>
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 9 조 정보주체의 권익침해에 대한 구제방법
                </h2>
                <p className="policy__desc">
                  회원은 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
                  등을 문의할 수 있습니다.
                  <span className="policy__desc__detail">
                    ① 개인정보 침해신고센터 (한국인터넷진흥원 운영)
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 소관업무 : 개인정보 침해사실 신고, 상담 신청
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 홈페이지 : http://privacy.kisa.or.kr
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 전화 : (국번없이) 118
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 주소 : (58324) 전남 나주시 진흥길 9 3층
                    개인정보침해신고센터
                  </span>
                  <span className="policy__desc__detail">
                    ② 개인정보 분쟁조정위원회
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적
                    해결)
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 홈페이지 : http://www.kopico.go.kr
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 전화 : (국번없이) 1833-6972
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사
                    4층
                  </span>
                  <span className="policy__desc__detail">
                    ③ 대검찰청 사이버범죄 수사단
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 홈페이지 : www.spo.go.kr
                  </span>
                  <span className="policy__desc__detail">
                    ④ 사이버범죄 신고시스템
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 전화 : (국번없이) 182
                  </span>
                  <span className="policy__desc__detail hyphen">
                    - 홈페이지 : https://ecrm.police.go.kr/minwon/main
                  </span>
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 10 조 고지의 의무</h2>
                <p className="policy__desc no">
                  1. 본 개인정보처리방침의 내용, 추가, 삭제 및 수정이 있을 경우
                  개정 최소 7일 전에 메스와이저 서비스의 초기화면을 통해 사전
                  공지를 할 것입니다. 다만, 수집하는 개인정보의 항목, 이용목적의
                  변경 등과 같이 회원 권리의 중대한 변경이 발생할 때에는 최소
                  30일 전에 공지하며, 필요 시 회원 동의를 다시 받을 수도
                  있습니다.
                </p>
                <span className="policy__desc__detail">
                  ① 공지일자 : 2023.08.01
                </span>
                <span className="policy__desc__detail">
                  ② 시행일자 : 2023.08.01
                </span>
              </div>
            </TextContents>
          </Container>
        </Wrapper>,
        document.getElementById("modal")
      )}
    </>
  );
}
/* 기존 애니메이션 */
const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
`;
const Container = styled.div`
  width: 50vw;
  /* max-height: 800px; */
  /* height: 50vw; */
  height: 80vh;
  overflow: hidden;

  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;
  position: absolute;
  top: 10vh;
  left: 25vw;
  background: #fff;
  opacity: 0;
  border-radius: 15px;

  padding: 0 15px 30px 30px;

  @media (max-width: 480px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: none;
    padding: 0 30px 30px;
    border-radius: 0;
  }
`;
const TextContents = styled.div`
  /* max-height: 692px; */
  max-height: 100%;
  height: fit-content;
  padding: 0 15px 80px 0;
  /* overflow-y: auto; */
  overflow-y: scroll;

  h2.policy__main__title {
    font-size: 20px;
  }
  h2.policy__title,
  h2.table__header {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    h2.policy__main__title {
      font-size: 18px;
    }
    h2.table__header {
      font-size: 11px;
    }
  }

  @media (max-width: 480px) {
    max-height: unset;
    height: calc(100% - 78px);
    padding: 0;
  }
`;
const Header = styled.div`
  display: flex !important;
  width: 100%;
  height: 78px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 10px 0 15px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;

  button {
    padding: 0;
    font-size: 35px;
  }
`;
