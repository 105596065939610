import { useHistory } from "react-router-dom";
import styled from "styled-components";
import icon_Logo from "../../assets/images/alvis_logo.svg";
import icon_mLogo from "../../assets/images/chatBotImg_new.svg";
import { useSpring, animated } from "react-spring/web";
import { isMobile } from "react-device-detect";

export default function GlobalHeader({ headerClass, isScrolledPastFirst }) {
  const history = useHistory();

  const chatbotUrl = `${process.env.REACT_APP_CHATBOT_HOST}`;
  const asstbotUrl = `${process.env.REACT_APP_ASSISTANT_HOST}`;

  const contentShow2 = useSpring({
    config: { duration: 300 },
    from: { y: -10, opacity: 0 },
    to: {
      y: isScrolledPastFirst ? 0 : -10,
      opacity: isScrolledPastFirst ? 1 : 0,
    },
  });

  return (
    <HeaderBackCt className={headerClass}>
      <HeaderCt className="header">
        {isMobile ? (
          !isScrolledPastFirst ? (
            <Logo
              icon_Logo={icon_Logo}
              onClick={(e) => {
                e.preventDefault();
                history.replace("/");
              }}
            />
          ) : (
            <Logo
              className={isScrolledPastFirst ? "active" : "none"}
              icon_Logo={icon_mLogo}
              onClick={(e) => {
                e.preventDefault();
                history.replace("/");
              }}
            />
          )
        ) : (
          <Logo
            icon_Logo={icon_Logo}
            onClick={(e) => {
              e.preventDefault();
              history.replace("/");
            }}
          />
        )}

        {isMobile ? (
          isScrolledPastFirst && (
            <animated.div style={{ ...contentShow2 }}>
              <ButtonCt>
                <HeaderBtn
                  className="mobile"
                  aria-label="챗봇 바로가기 버튼"
                  onClick={() => {
                    window.open(chatbotUrl, "_blank");
                  }}
                >
                  Chatbot <i className="ri-external-link-line"></i>
                </HeaderBtn>
                <HeaderBtn
                  className="mobile"
                  aria-label="어시스턴트 바로가기 버튼"
                  onClick={() => {
                    window.open(asstbotUrl, "_blank");
                  }}
                >
                  Assistant <i className="ri-external-link-line"></i>
                </HeaderBtn>
              </ButtonCt>
            </animated.div>
          )
        ) : (
          <ButtonCt>
            <HeaderBtn
              className=""
              aria-label="챗봇 바로가기 버튼"
              onClick={() => {
                window.open(chatbotUrl, "_blank");
              }}
            >
              Chatbot <i className="ri-external-link-line"></i>
            </HeaderBtn>
            <HeaderBtn
              className=""
              aria-label="어시스턴트 바로가기 버튼"
              onClick={() => {
                window.open(asstbotUrl, "_blank");
              }}
            >
              Assistant <i className="ri-external-link-line"></i>
            </HeaderBtn>
          </ButtonCt>
        )}
      </HeaderCt>
    </HeaderBackCt>
  );
}

const HeaderBackCt = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background-color: #fff;
  width: 100%;
  flex: 0 0 auto;

  /* box-shadow: 0px -6px 22px 0px #00000040; */
  box-shadow: 0px 0px 22px 0px #00000040;
  z-index: 1;

  padding: 0 25px;

  &.home {
    position: fixed;
    /* padding-top: 64px; */
  }
`;

const HeaderCt = styled.header`
  width: 100%;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 620px) {
    padding: 0 1vw;
  }

  @media screen and (max-width: 480px) {
    justify-content: flex-start;
  }
`;

const Logo = styled.a`
  margin-right: auto;
  width: 220px;
  height: 34px;
  background: url(${({ icon_Logo }) => icon_Logo}) no-repeat;
  background-size: contain;
  background-position: center left;
  color: transparent;

  cursor: pointer;

  @media (max-width: 480px) {
    max-width: 165px;
    margin-right: 0;
    &.active {
      width: 70px;
    }
  }
`;

const ButtonCt = styled.div`
  display: flex;
`;

const HeaderBtn = styled.button`
  padding: 10px 15px;
  margin: 0 10px;
  border-radius: 50px;
  color: #222;
  font-weight: 600;

  @media (min-width: 481px) {
    &:first-child {
      position: relative;
      &:after {
        content: "";
        background: #dcdcdc;
        position: absolute;
        width: 1px;
        height: 50%;
        top: 50%;
        transform: translateY(-50%);
        right: -10%;
      }
    }
  }

  .ri-external-link-line {
    color: #ff892a;
  }

  &.mobile {
    background: #ff892a;
    color: #fff;
    font-size: 12px;
    padding: 10px;
    width: 100%;
    flex: 0 0 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ri-external-link-line {
      color: #fff;
      margin-left: 5px;
    }
  }
`;
