import { Route, Switch } from "react-router-dom";
import Home from "./home";
import PersonalPolicy from "./docs/PersonalPolicy";
import TermOfUsePolicy from "./docs/TermOfUsePolicy";
import MarketingPolicy from "./docs/MarketingPolicy";
import FaqPage from "./docs/FaqPage";
import Event202401 from "components/pages/promotion/Event202401";

export default function Router() {
  return (
    <>
      <Switch>
        <Route path="/promotion" render={(props) => <Event202401 />} />
        <Route path="/personalPolicy" render={(props) => <PersonalPolicy />} />
        <Route
          path="/termOfUsePolicy"
          render={(props) => <TermOfUsePolicy />}
        />

        <Route
          path="/marketingPolicy"
          render={(props) => <MarketingPolicy />}
        />

        <Route path="/faqPage" render={(props) => <FaqPage />} />
        <Route path="/" render={(props) => <Home />} />
      </Switch>
    </>
  );
}
